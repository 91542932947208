import React from 'react';
import './Navigation.css';
import SiteLogo from '../../assets/FEMA-logo-white.png';
import { translationContext } from '../../App';
import CookieOverlay from '../../components/ErrorOverlay/CookieOverlay';
import { Link } from 'react-router-dom';
import { trackLanguageChange } from '../../utils/analyticEvents';
function Navigation({ userLanguage, setUserLanguage, englishLabel, spanishLabel }) {
  const localTranslationContext = React.useContext(translationContext);

  const [cookieOverlayOpen, setCookieOverlayOpen] = React.useState(false);

  const [isNavExpanded, setIsNavExpanded] = React.useState(false);
  function handleNavigationClick() {
    setIsNavExpanded(!isNavExpanded);
    const navClose = document.querySelector('.navigation-flyout-close');
    if (isNavExpanded == true) {
      navClose.focus();
    }
  }
  function handleLogout() {
    document.cookie = 'regNum=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; // Clear the 'regNum' cookie
    document.cookie = 'PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; // Clear the 'regNum' cookie
    window.location.href = '';
  }
  return (
    <nav className="navigation-wrap" data-lang={userLanguage}>
      <div className="navigation-flex">
        <div className="navigation-flex-left">
          <button className="navigation-menu" onClick={handleNavigationClick}>
            <span className="material-symbols-rounded">menu</span>
          </button>
          <a className="navigation-logo" href="/">
            <img src={SiteLogo} alt="FEMA site logo" width="127" height="45" />
          </a>
          <p className="navigation-title">
            {localTranslationContext.translations.find((translation) => translation.code === 'TSA').text}
          </p>
        </div>
        <div className="navigation-flex-right">
          <div className="navigation-translate">
            <select
              className="navigation-translate-select"
              value={userLanguage}
              onChange={(e) => {
                setUserLanguage(e.target.value);
                trackLanguageChange('Language Change', e.target.value);
              }}
              aria-label="Select site language"
            >
              <option value="en">{englishLabel}</option>
              <option value="es">{spanishLabel}</option>
            </select>
            <div className="navigation-translate-select-arrow material-symbols-rounded">expand_more</div>
          </div>
        </div>
      </div>
      <div
        className={isNavExpanded ? 'navigation-flyout-wrap expanded' : 'navigation-flyout-wrap'}
        // aria-hidden={isNavExpanded ? 'false' : 'true'}
      >
        <div className="navigation-flyout">
          <button className="navigation-flyout-close" onClick={handleNavigationClick}>
            <span className="material-symbols-rounded">close</span>
          </button>
          <div className="navigation-flyout-branding">
            <a className="navigation-flyout-logo" href="" title="Return to Search">
              <img src={SiteLogo} alt="FEMA site logo" />
            </a>
            <p className="navigation-flyout-title" data-lang={userLanguage}>
              {localTranslationContext.translations.find((translation) => translation.code === 'TSA').text}
            </p>
          </div>
          <div className="navigation-flyout-link-wrap">
            <Link className="navigation-flyout-link" to="/find">
              <span className="navigation-flyout-link-icon material-symbols-rounded" aria-hidden="true">
                search
              </span>
              {localTranslationContext.translations.find((translation) => translation.code === 'Menu-Find').text}
            </Link>
            <Link className="navigation-flyout-link" to="/help">
              <span className="navigation-flyout-link-icon material-symbols-rounded" aria-hidden="true">
                help
              </span>
              {localTranslationContext.translations.find((translation) => translation.code === 'Menu-Help').text}
            </Link>
            <button className="navigation-flyout-link" onClick={() => setCookieOverlayOpen(true)}>
              <span className="navigation-flyout-link-icon material-symbols-rounded" aria-hidden="true">
                cookie
              </span>
              {localTranslationContext.translations.find((translation) => translation.code === 'Cookie-Policy').text}
            </button>
            <a className="navigation-flyout-link" href="" onClick={handleLogout}>
              <span className="navigation-flyout-link-icon material-symbols-rounded" aria-hidden="true">
                logout
              </span>
              {localTranslationContext.translations.find((translation) => translation.code === 'Menu-Log').text}
            </a>
          </div>
        </div>
      </div>
      {cookieOverlayOpen && <CookieOverlay setCookieOverlayOpen={setCookieOverlayOpen} />}
    </nav>
  );
}

export default Navigation;
