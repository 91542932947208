export const trackNumberOfSearchInSession = () => {
  let currentSearches = sessionStorage.getItem('numberOfSearches');
  let count = currentSearches ? parseInt(currentSearches, 10) : 0;
  count++;
  sessionStorage.setItem('numberOfSearches', count.toString());
  return count;
};

export const trackNumberOfCallClicksInSession = () => {
  let currentCalls = sessionStorage.getItem('numberOfCallClicks');
  let count = currentCalls ? parseInt(currentCalls, 10) : 0;
  count++;
  sessionStorage.setItem('numberOfCallClicks', count.toString());
  return count;
};
export const trackNumberOfHotelViewedInSession = () => {
  let currentHotelsViewed = sessionStorage.getItem('numberOfHotelViewed');
  let count = currentHotelsViewed ? parseInt(currentHotelsViewed, 10) : 0;
  count++;
  sessionStorage.setItem('numberOfHotelViewed', count.toString());
  return count;
};
export const trackSearchResponseEvent = (
  userLanguage,
  locationEntered,
  lat,
  long,
  radius,
  filters,
  numberOfResults,
  numberOfFilterdResults,
  numberOfSearches
) => {
  if (window.gtag) {
    window.gtag('event', 'Search Response', {
      user_language: userLanguage,
      number_of_searches: numberOfSearches,
      location_entered: locationEntered,
      'lat, long': lat + ', ' + long,
      radius: radius,
      pets_allowed: filters.petsAllowed,
      ada_accomodations: filters.adaAccomodations,
      meals_provided: filters.mealsProvided,
      number_of_results: numberOfResults,
      number_of_filtered_results: numberOfFilterdResults,
      'filteredResults, totalResults': numberOfFilterdResults + ', ' + numberOfResults,
      // How many searches did the user conduct before clicking on the first hotel?
    });
  }
};

export const trackHotelView = (
  language,
  hotelId,
  hotelName,
  hasPhotos,
  searchResultsRank, // depends on whether they are coming from list or map
  distance,
  viewSource // either 'list' or 'map'
) => {
  if (window.gtag) {
    window.gtag('event', 'Hotel View', {
      hotel_name: hotelName,
      user_language: language,
      hotel_id: hotelId,
      has_photos: hasPhotos,
      search_results_rank: searchResultsRank,
      distance: distance,
      src_list_or_map: viewSource,
      total_hotel_viewed: trackNumberOfHotelViewedInSession(),
      total_number_of_searches: sessionStorage.getItem('numberOfSearches'),
      'hotels viewed / searches':
        sessionStorage.getItem('numberOfHotelViewed') + ' / ' + sessionStorage.getItem('numberOfSearches'),
    });
  }
};

export const trackCallClick = (
  language,
  hotelId,
  hotelName,
  hasPhotos,
  searchResultsRank,
  distance,
  viewSource // either 'list' or 'map'
) => {
  if (window.gtag) {
    window.gtag('event', 'Call Click', {
      hotel_name: hotelName,
      user_language: language,
      hotel_id: hotelId,
      has_photos: hasPhotos,
      search_results_rank: searchResultsRank,
      distance: distance,
      src_list_or_map: viewSource,
      total_number_called: trackNumberOfCallClicksInSession(),
      total_number_of_searches: sessionStorage.getItem('numberOfSearches'),
      'call clicks / searches':
        sessionStorage.getItem('numberOfCallClicks') + ' / ' + sessionStorage.getItem('numberOfSearches'),
    });
  }
};

export function trackMapInteraction(action, label = '') {
  if (window.gtag) {
    window.gtag('event', 'Map Interactions', {
      event_category: action,
      event_label: label,
    });
  }
}

export function trackAlternatePaths(action, label = '') {
  if (window.gtag) {
    window.gtag('event', 'Alternate Paths', {
      event_category: action,
      event_label: label,
    });
  }
}

export const trackLanguageChange = (action, label = '') => {
  if (window.gtag) {
    window.gtag('event', 'Language Change', {
      change_count: action,
      language_selected: label,
    });
  }
};
