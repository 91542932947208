import React, { useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './ErrorOverlay.css';
import { translationContext } from '../../App';
import { RefreshTimerContext } from '../../contexts/RefreshTimerContext';

function RefreshOverlay({ setRefreshOverlayOpen }) {
  const localTranslationContext = useContext(translationContext);
  const dictionary = localTranslationContext.translations;
  const { handleResetTimer } = useContext(RefreshTimerContext);

  const RefreshText = dictionary.find((translation) => translation.code === 'Refresh-Text').text;
  const RefreshButton = dictionary.find((translation) => translation.code === 'Refresh-Button').text;
  const overlayRootRef = useRef(null);
  const closeButtonRef = useRef(null);
  const handleClose = () => {
    sessionStorage.setItem('resultsCallTimeStamp', new Date().toString());
    setRefreshOverlayOpen(false);
    handleResetTimer();
  }

  const handleRefresh = (e) => {
    sessionStorage.setItem('isRefresh', 'true');
    window.location.reload();
  }

  const handleCloseOnEnter = (event) => {
    // Check if 'Enter' was pressed
    if (event.key === 'Enter') {
      handleClose();
    }
  };

  const handleRefreshOnEnter = (event) => {
    // Check if 'Enter' was pressed
    if (event.key === 'Enter') {
      handleRefresh();
    }
  };

  useEffect(() => {
    const overlayRoot = overlayRootRef.current;
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const focusableContent = Array.from(overlayRoot.querySelectorAll(focusableElements));
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    const trapFocus = (event) => {
      if (document.activeElement === firstFocusableElement && event.shiftKey && event.key === 'Tab') {
        event.preventDefault();
        lastFocusableElement.focus();
      } else if (document.activeElement === lastFocusableElement && !event.shiftKey && event.key === 'Tab') {
        event.preventDefault();
        firstFocusableElement.focus();
      }
    };

    overlayRoot.addEventListener('keydown', trapFocus);
    // focus on closebutton initially
    closeButtonRef.current.focus();

    return () => overlayRoot.removeEventListener('keydown', trapFocus);
  }, []);


  return (
    <div  ref={overlayRootRef} className="refresh-overlay-wrap">
      <div className="error-overlay">
        <a className="error-overlay-close refresh-overlay-close" onClick={handleClose} ref={closeButtonRef} tabIndex="0" onKeyDown={handleCloseOnEnter}>
          <span className="material-symbols-rounded">close</span>
        </a>
        <p className="error-overlay-headline">{RefreshText}</p>
        <div className="error-overlay-button-wrap">
          <button className="error-overlay-button"  tabIndex="0"  onClick={handleRefresh} onKeyDown={handleRefreshOnEnter    }>
            {RefreshButton}
          </button>
        </div>
      </div>
    </div>
  );
}
export default RefreshOverlay;
