import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import './slick.css';

export default function SimpleSlider({hotelName, images}) {
  var settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [imageArray, setImageArray] = useState(images);

  return (
    <Slider {...settings}>
       {imageArray.map((image, index) => {
        const className = image.isValid ? 'results-list-item-image' : 'results-list-item-image bed';
        return <div className={className} key={index}>
                <img src={image.url} alt={hotelName + index} width="604" height="425" loading="lazy" />
              </div>
      })}
    </Slider>
  );
}
