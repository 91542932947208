import React from 'react';
import './Loading.css';

function Loading({ isLoading}) {
  return (
    <div className="loading-overlay" style={{display: isLoading ? 'flex' : 'none'}}>
      <div className="loading-spinner">&nbsp;</div>
    </div>
  );
}

export default Loading;
