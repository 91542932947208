import React, { useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './ErrorOverlay.css';
import { translationContext } from '../../App';

function ForgotOverlay({ setForgotOverlayOpen }) {
  const localTranslationContext = useContext(translationContext);
  const dictionary = localTranslationContext.translations;

  const ForgotRegNumber1 = dictionary.find((translation) => translation.code === 'Forgot-RegNumber-Detail-1').text;
  const Back = dictionary.find((translation) => translation.code === 'Back').text;
  const overlayRootRef = useRef(null);
  const closeButtonRef = useRef(null);
  const handleCloseOnEnter = (event) => {
    // Check if 'Enter' was pressed
    if (event.key === 'Enter') {
      setForgotOverlayOpen(false);
    }
  };

  useEffect(() => {
    const overlayRoot = overlayRootRef.current;
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const focusableContent = Array.from(overlayRoot.querySelectorAll(focusableElements));
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];
    const trapFocus = (event) => {
      if (document.activeElement === firstFocusableElement && event.shiftKey && event.key === 'Tab') {
        event.preventDefault();
        lastFocusableElement.focus();
      } else if (document.activeElement === lastFocusableElement && !event.shiftKey && event.key === 'Tab') {
        event.preventDefault();
        firstFocusableElement.focus();
      }
    };

    overlayRoot.addEventListener('keydown', trapFocus);
    // focus on closebutton initially
    closeButtonRef.current.focus();

    return () => overlayRoot.removeEventListener('keydown', trapFocus);
  }, []);

  return (
    <div ref={overlayRootRef} className="forgot-overlay-wrap">
      <div className="error-overlay">
        <a
          ref={closeButtonRef}
          tabIndex="0"
          onKeyDown={handleCloseOnEnter}
          className="error-overlay-close forgot-overlay-close"
          onClick={() => setForgotOverlayOpen(false)}
        >
          <span className="material-symbols-rounded">close</span>
        </a>
        {/* <p className="error-overlay-headline">[Forgot Number Headline]</p> */}
        <div className="error-overlay-text wide" dangerouslySetInnerHTML={{ __html: ForgotRegNumber1 }}></div>
        <div className="error-overlay-button-wrap">
          <a
            tabIndex="0"
            className="error-overlay-button"
            onClick={() => setForgotOverlayOpen(false)}
            onKeyDown={handleCloseOnEnter}
          >
            {Back}
          </a>
        </div>
      </div>
    </div>
  );
}
export default ForgotOverlay;
