import React, { useEffect, useState, useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';
import config from '../../config.json';
import { translationContext } from '../../App';
import { urlParamsContext } from '../../App.js';

/**
 * Enable "Enter" key support for the autocomplete
 * @param {HTMLInputElement} input - The input El
 */
function enableEnterKey(input) {
  if (!input) return;

  /* Store original event listener */
  const _addEventListener = input.addEventListener;

  const addEventListenerWrapper = (type, listener) => {
    if (type === 'keydown') {
      /* Store existing listener function */
      const _listener = listener;
      listener = (event) => {
        /* Simulate a 'down arrow' keypress if no address has been selected */
        const suggestionSelected = document.getElementsByClassName('pac-item-selected').length;
        if (event.key === 'Enter' && !suggestionSelected) {
          const e = new KeyboardEvent('keydown', {
            key: 'ArrowDown',
            code: 'ArrowDown',
            keyCode: 40,
          });
          _listener.apply(input, [e]);
        }

        _listener.apply(input, [event]);
      };
    }
    _addEventListener.apply(input, [type, listener]);
  };

  input.addEventListener = addEventListenerWrapper;
}

/**
 * Select the first location
 * @param {HTMLInputElement} input - The input El
 */
function selectFirstLocation(input) {
  input.dispatchEvent(
    new KeyboardEvent('keydown', {
      key: 'ArrowDown',
      code: 'ArrowDown',
      keyCode: 40, // down arrow
    })
  );
  input.dispatchEvent(
    new KeyboardEvent('keydown', {
      key: 'ArrowDown',
      code: 'ArrowDown',
      keyCode: 13, // return key
    })
  );
}

export default function LocationSearchInput() {
  const dictionary = React.useContext(translationContext);
  const urlParams = React.useContext(urlParamsContext);
  const [searchLabel, setSearchLabel] = useState();
  const inputElement = useRef();
  const ValidLocation = dictionary?.translations?.find((x) => x.code === 'Valid-Location').text;

  useEffect(() => {
    const searchText = dictionary?.translations?.find((x) => x.code === 'Search-Results-bar').text;
    if (searchText !== undefined && searchText !== '' && searchText !== null) {
      setSearchLabel(searchText);
    }
  }, [dictionary?.versionCounter]);
 
  // Automatically do a search on mount if location is in the URL
  useEffect(() => {
    const tempUrlParams = new URLSearchParams(window.location.hash.split('?')[1]);
    const locationName = tempUrlParams.get('search');
    if (locationName && !urlParams.search) { // Check if urlParams.search is empty
      inputElement.current.focus(); // Set focus
      setTimeout(() => {
        selectFirstLocation(inputElement.current);
      }, 1000);
    }
    enableEnterKey(inputElement.current);
  }, []);

  
  return (
    <div className={('results-list-search-options-search-bar', urlParams.showError ? ' error' : '')}>
      <Autocomplete
        ref={inputElement}
        className="results-list-search-input"
        type="search"
        aria-label={searchLabel}
        placeholder={searchLabel}
        apiKey={config.GOOGLE_MAPS_KEY} // Use apiKey or clientKey
        value={urlParams.search || ''}
        onChange={async (e) => {
          urlParams.setSearch(e.target.value);
          urlParams.setLatitude(undefined);
          urlParams.setLongitude(undefined);
          urlParams.setShowError(false);
          urlParams.setLocationSelected(false);

        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && (!e.target.value || e.target.value.trim() === '')) {
            urlParams.setShowError(true);
          }
        }}
        //clientKey={config.GOOGLE_MAPS_CLIENT_ID}
        onPlaceSelected={(place, ref) => {
          urlParams.setShowError(false);
          if (!place || place.name?.replace(/\s+/g, '') === '' || place === undefined) {
            //Only set Invalid location if it has been interacted with (not undefined)
            if (place !== undefined) {
              urlParams.setLocationSelected(false);
            }
            //urlParams.setShowError(true);
            return;
          }
          let latitude = parseFloat(place.geometry?.location?.toJSON().lat);
          let longitude = parseFloat(place.geometry?.location?.toJSON().lng);
          if (place?.address_components?.length > 0) {
            //The index 1 of this list is the top item selected by the Enter
            let code = place?.address_components[0]?.short_name;
            let address = place?.address_components[1]?.long_name;
            let state = place?.address_components[3]?.short_name;
            let fullAddress =
              ref?.value !== undefined && ref?.value !== '' && ref?.value !== null && ref?.value.length > code.length
                ? ref.value
                : code + ' ' + address + ', ' + state;
            urlParams.setLatitude(latitude);
            urlParams.setLongitude(longitude);
            urlParams.setSearch(fullAddress);
            urlParams.setLocationSelected(true);
          }
          else{
            urlParams.setLocationSelected(false);
            urlParams.setShowError(true);

          }
        }}
        options={{
          types: ['street_address', 'postal_code', 'locality', 'administrative_area_level_2'],
          componentRestrictions: { country: ['us', 'pr', 'vi', 'gu', 'mp'] },
          fields: ['address_components', 'geometry'],
        }}
      />
      <button
        className="results-list-search-submit"
         onMouseDown={(e) => e.preventDefault()}
        disabled={urlParams.showError}
        onClick={() => {
          if(urlParams.search !== ''){
            selectFirstLocation(inputElement.current);

          }
          else{
            urlParams.setShowError(true);
          }
        }}
      >
        <span className="material-symbols-rounded">search</span>
      </button>
      {urlParams.showError && <p className="results-list-search-error">{ValidLocation}</p>}
    </div>
  );
}
