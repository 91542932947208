import React from 'react';

function Autocomplete({ suggestions, onSuggestionClick }) {

  const handleKeydown = (e, suggestion) => {
    if (e.keyCode === 13) {
      onSuggestionClick(suggestion);
    }
  }
  
  return (
    <div className="autocomplete">
      {suggestions.map((suggestion, index) => (
        <div
          key={index}
          className="suggestion"
          tabIndex="0"
          onClick={() => onSuggestionClick(suggestion)}
          onKeyDown={(e) => handleKeydown(e, suggestion)}
        >
          {suggestion}
        </div>
      ))}
    </div>
  );
}

export default Autocomplete;
