import React, { useState, useEffect } from 'react';
import './Help.css';
import Navigation from '../../components/Navigation/Navigation';
import { translationContext } from '../../App';

function Help() {
  const translations = React.useContext(translationContext);
  const dictionary = translations.translations;
  const language = translations.language;
  const setLanguage = translations.dictionaryDispatch;
  const [englishLabel, setEnglishLabel] = useState(language);
  const [userLanguage, setUserLanguage] = useState('');
  const [spanishLabel, setSpanishLabel] = useState('');

  let TSA = dictionary.find((translation) => translation.code === 'TSA').text;
  let NeedHelp = dictionary.find((translation) => translation.code === 'Need-Help').text;
  let FAQ001 = dictionary.find((translation) => translation.code === 'FAQ001').text;
  let FAQ001HTML = dictionary.find((translation) => translation.code === 'FAQ001HTML').text;
  let FAQ002 = dictionary.find((translation) => translation.code === 'FAQ002').text;
  let FAQ002HTML = dictionary.find((translation) => translation.code === 'FAQ002HTML').text;
  let FAQ003 = dictionary.find((translation) => translation.code === 'FAQ003').text;
  let FAQ003HTML = dictionary.find((translation) => translation.code === 'FAQ003HTML').text;
  let FAQ004 = dictionary.find((translation) => translation.code === 'FAQ004').text;
  let FAQ004HTML = dictionary.find((translation) => translation.code === 'FAQ004HTML').text;
  let FAQ005 = dictionary.find((translation) => translation.code === 'FAQ005').text;
  let FAQ005HTML = dictionary.find((translation) => translation.code === 'FAQ005HTML').text;

  document.title = `${NeedHelp} | ${TSA}`;

  function setLanguageLabels(outerWidth) {
    if (outerWidth < 750) {
      setEnglishLabel('EN');
      setSpanishLabel('ES');
    } else {
      setEnglishLabel('English');
      setSpanishLabel('Español');
    }
  }

  //Get user language from browser
  useEffect(() => {
    if (userLanguage === '') {
      let userLang = navigator.language || navigator.userLanguage;
      userLang = userLang.substring(0, 2);
      if (userLang !== 'en' && userLang !== 'es') {
        userLang = 'en';
      }
      setUserLanguage(userLang);
      setLanguageLabels(window.outerWidth);
    }
  }, [userLanguage]);

  const Accordion = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleVisibilityToggle = () => setIsOpen(!isOpen);
    return (
      <section className="accordion-section" data-expanded={isOpen}>
        <h2 className="accordion-heading">
          <button
            aria-controls={props.FAQID}
            aria-expanded={isOpen}
            className="accordion-button"
            onClick={handleVisibilityToggle}
          >
            {props.Question}
          </button>
        </h2>
        <div
          className="accordion-content"
          id={props.FAQID}
          hidden={!isOpen}
          dangerouslySetInnerHTML={{ __html: props.Answer }}
        ></div>
      </section>
    );
  };

  return (
    <div className="help-page-wrap">
      <Navigation
        userLanguage={language}
        setUserLanguage={setLanguage}
        englishLabel={englishLabel}
        spanishLabel={spanishLabel}
      />
      <div className="help-page-center">
        <h1 className="help-page-headline">{NeedHelp}</h1>
        <Accordion Question={FAQ001} Answer={FAQ001HTML} FAQID="faq001" />
        <Accordion Question={FAQ002} Answer={FAQ002HTML} FAQID="faq002" />
        <Accordion Question={FAQ003} Answer={FAQ003HTML} FAQID="faq003" />
        <Accordion Question={FAQ004} Answer={FAQ004HTML} FAQID="faq004" />
        <Accordion Question={FAQ005} Answer={FAQ005HTML} FAQID="faq005" />
      </div>
    </div>
  );
}

export default Help;
