import React, { createContext, useState, useEffect, useContext } from 'react';
import { urlParamsContext } from '../App';
import { useError } from '../contexts/ErrorContext';
import { translationContext } from '../App';
import { RefreshTimerContext } from '../contexts/RefreshTimerContext';
import apiAdress from '../utils/apiAddress';
import { trackNumberOfSearchInSession, trackSearchResponseEvent } from '../utils/analyticEvents';
export const HotelContext = createContext();

export const HotelProvider = ({ children, parameters }) => {
  /*Start Context*/
  const urlParams = React.useContext(urlParamsContext);
  const localTranslationContext = useContext(translationContext);
  /*End Context*/

  /*Start States  */
  const dictionary = localTranslationContext.translations;
  const radiusIncrements = [25, 50, 100, 250, 500, 1000, 3000, 10000];
  const [allHotels, setAllHotels] = useState([]);
  const [filteredHotels, setFilteredHotels] = useState([]);
  const [nextRadius, setNextRadius] = useState(50);
  const [isGeo, _setIsGeo] = useState(false);
  const Unlimited = dictionary.find((translation) => translation.code === 'Unlimited').text;
  const [isLoading, setIsLoading] = useState(false);
  const { handleResetTimer } = useContext(RefreshTimerContext);
  const [filters, setFilters] = useState(urlParams.filterOptions);
  const [hotelNameFilter, setHotelNameFilter] = useState([]);
  const [filterCount, setFilterCount] = useState(
    [filters.petsAllowed, filters.adaAccommodations, filters.mealsProvided, filters.hotelNames.length > 0].filter(
      Boolean
    ).length
  );
  const setIsGeo = (value) => {
    _setIsGeo(value);
    urlParams.setIsGeo(value);
  };

  const { setError } = useError();
  /*End States  */
  const cleanHotelName = (name) => name.replace(/\s*\([^)]*\)\s*/g, '');

  const handleExpandingRadius = () => {
    setIsLoading(true);
    let currentIndex = radiusIncrements.indexOf(Number(urlParams.radius));
    if (nextRadius !== undefined && nextRadius !== { Unlimited }) {
      urlParams.setRadius(nextRadius);
      setNextRadius(radiusIncrements[currentIndex + 1] !== 10000 ? radiusIncrements[currentIndex + 2] : Unlimited);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setError({
        type: 'noHotelsMaxRadius',
        show: true,
      });
    }
  };

  const fetchHotels = (latitude, longitude, radius, regNum) => {
    setIsLoading(true);
    return fetch(
      apiAdress +
        '/evac_hotel_api.php?validate=FEMAEvacAPI' +
        '&latitude=' +
        latitude +
        '&longitude=' +
        longitude +
        '&radius=' +
        radius +
        '&reg_number=' +
        regNum,
      {
        method: 'GET',
        headers: { 'Content-Type': '*' },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.Status.toLowerCase() === 'success' && data.Hotels.length > 0) {
          handleResetTimer();
          data.Hotels.forEach((hotel) => {
            let distance = getDistanceFromLatLonInKm(latitude, longitude, hotel.Latitude, hotel.Longitude);
            hotel.Distance = Math.round(distance * 10) / 10;
          });
          let newHotels = data.Hotels.sort((a, b) => (a.Distance > b.Distance ? 1 : -1));
          setAllHotels(newHotels);
          sessionStorage.setItem('resultsCallTimeStamp', new Date().toString());
          sessionStorage.setItem('totalResults', newHotels.length);
          setIsGeo(data.isGeo);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setAllHotels([]);
          sessionStorage.setItem('totalResults', 0);
        }

        checkForSearchChange(urlParams.latitude, urlParams.longitude, urlParams.radius, urlParams.filterOptions);

        sessionStorage.setItem('latitude', urlParams.latitude.toString());
        sessionStorage.setItem('longitude', urlParams.longitude.toString());
        sessionStorage.setItem('radius', urlParams.radius.toString());
        return data;
      })
      .catch((err) => {
        setIsLoading(false);
        setError({
          type: 'unexpected',
          show: true,
        });
        throw err;
      });
  };

  const fetchHotelsWithoutLoading = async (latitude, longitude, radius, regNum) => {
    return fetch(
      apiAdress +
        '/evac_hotel_api.php?validate=FEMAEvacAPI' +
        '&latitude=' +
        latitude +
        '&longitude=' +
        longitude +
        '&radius=' +
        radius +
        '&reg_number=' +
        regNum,
      {
        method: 'GET',
        headers: { 'Content-Type': '*' },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        if (data.Status.toLowerCase() === 'success' && data.Hotels.length > 0) {
          handleResetTimer();
          data.Hotels.forEach((hotel) => {
            let distance = getDistanceFromLatLonInKm(latitude, longitude, hotel.Latitude, hotel.Longitude);
            hotel.Distance = Math.round(distance * 10) / 10;
          });
          let newHotels = data.Hotels.sort((a, b) => (a.Distance > b.Distance ? 1 : -1));
          setAllHotels(newHotels);

          sessionStorage.setItem('resultsCallTimeStamp', new Date().toString());
          sessionStorage.setItem('totalResults', newHotels.length);

          let results = {
            allHotels: newHotels,
            filteredHotels: await filterResults(newHotels),
          };
          return results;
        } else {
          setIsGeo(data.isGeo);
          setAllHotels([]);
          sessionStorage.setItem('totalResults', 0);

        }
        checkForSearchChange(urlParams.latitude, urlParams.longitude, urlParams.radius, urlParams.filterOptions);
        return data;
      })
      .catch((err) => {
        setIsLoading(false);
        setError({
          type: 'unexpected',
          show: true,
        });
        throw err;
      });
  };

  /* Use Effect Start */
  useEffect(() => {
    setNextRadius(getNextRadius(urlParams.radius));
  }, [urlParams.radius]);

  useEffect(() => {
    filterResults(allHotels);
  }, [allHotels, urlParams.filterOptions, urlParams.filterInUse]);

  /* Use Effect End */
  const getNextRadius = (radius) => {
    let currentIndex = radiusIncrements.indexOf(Number(radius));
    return radiusIncrements[currentIndex + 1];
  };

  const filterResults = async (data) => {
    let filtered = data;

    if (urlParams.filterOptions.petsAllowed) {
      filtered = filtered.filter((hotel) => hotel.Amenities.Pets === 'Y');
      urlParams.setIsPetsAllowed(true);
    } else {
      urlParams.setIsPetsAllowed(false);

    }

    if (urlParams.filterOptions.adaAccomodations) {
      filtered = filtered.filter((hotel) => hotel.Amenities.ADA === 'Y');
      urlParams.setIsAdaAccomodations(true);
    } else {
      urlParams.setIsAdaAccomodations(false);
    }

    if (urlParams.filterOptions.mealsProvided) {
      filtered = filtered.filter((hotel) => hotel.Amenities.Kitchen === 'Y');
      urlParams.setIsMealsProvided(true);
    } else {
      urlParams.setIsMealsProvided(false);

    }
    //hotel search in filter overlay
    setHotelNameFilter(filtered);
    if (urlParams.filterOptions.hotelNames.length > 0) {
      filtered = filtered.filter((hotel) => urlParams.filterOptions.hotelNames.includes(cleanHotelName(hotel.Name)));
      urlParams.setSelectedFilterHotelNames(urlParams.filterOptions.hotelNames);
      

    } else {
      urlParams.setSelectedFilterHotelNames([]);

    }
    setFilteredHotels(filtered);
    sessionStorage.setItem('totalFiltered', filtered.length);
    setFilterCount(
      [
        urlParams.filterOptions.petsAllowed,
        urlParams.filterOptions.adaAccomodations,
        urlParams.filterOptions.mealsProvided,
        urlParams.filterOptions.hotelNames.length > 0,
      ].filter(Boolean).length
    );
    checkForSearchChange(urlParams.latitude, urlParams.longitude, urlParams.radius, urlParams.filterOptions);
    sessionStorage.setItem('isPetsAllowed', urlParams.filterOptions.petsAllowed);
    sessionStorage.setItem('isMealsProvided',  urlParams.filterOptions.mealsProvided);
    sessionStorage.setItem('isAdaAccomodations',  urlParams.filterOptions.adaAccomodations);

    return await filtered;
  };

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = (R * c) / 1.609; // Distance in miles
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  function checkForSearchChange(currentLatitude, currentLongitude, currentRadius, filterOptions) {
    // Check if any of the passed parameters are undefined or null
    if (!currentLatitude || !currentLongitude || !currentRadius) {
      return;  // Exit the function if any of these are undefined or null
    }
  
    const storedLatitude = sessionStorage.getItem('latitude');
    const storedLongitude = sessionStorage.getItem('longitude');
    const storedRadius = sessionStorage.getItem('radius');
    
    // Default filter values in case filterOptions is undefined or null
    let petsAllowed = false;
    let adaAccomodations = false;
    let mealsProvided = false;
    let hotelNames = [];
    
    if (filterOptions) {
      petsAllowed = filterOptions.petsAllowed;
      adaAccomodations = filterOptions.adaAccomodations;
      mealsProvided = filterOptions.mealsProvided;
      hotelNames = filterOptions.hotelNames || [];
    }
  
    // Add storage for filter options 
    const storedPetsAllowed = sessionStorage.getItem('isPetsAllowed') === 'true';
    const storedAdaAccomodations = sessionStorage.getItem('isAdaAccomodations') === 'true';
    const storedMealsProvided = sessionStorage.getItem('isMealsProvided') === 'true';
    const isRefresh = sessionStorage.getItem('isRefresh') === 'true';
    const totalFiltered = sessionStorage.getItem('totalFiltered');
    const totalResults = sessionStorage.getItem('totalResults');


    currentLatitude = currentLatitude.toString();
    currentLongitude = currentLongitude.toString();
    currentRadius = currentRadius.toString();

    if (
      currentLatitude !== storedLatitude ||
      currentLongitude !== storedLongitude ||
      currentRadius !== storedRadius||
      petsAllowed !== storedPetsAllowed ||
      adaAccomodations !== storedAdaAccomodations ||
      mealsProvided !== storedMealsProvided || isRefresh
    ) {
      trackSearchResponseEvent(
        localTranslationContext.language,
        urlParams.search,
        urlParams.latitude,
        urlParams.longitude,
        urlParams.radius,
        urlParams.filterOptions,
        totalResults,
        totalFiltered,
        trackNumberOfSearchInSession()
      );
    }
    sessionStorage.setItem('isRefresh', 'false');
  }
  
  
  

  return (
    <HotelContext.Provider
      value={{
        filterCount,
        filters,
        setIsLoading,
        fetchHotels,
        isLoading,
        allHotels,
        filteredHotels,
        nextRadius,
        handleExpandingRadius,
        setFilterCount,
        isGeo,
        setFilters,
        setNextRadius,
        setAllHotels,
        fetchHotelsWithoutLoading,
        hotelNameFilter,
      }}
    >
      {children}
    </HotelContext.Provider>
  );
};
