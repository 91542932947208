import React, { useContext, useEffect, useState, useRef } from 'react';
import './FiltersOverlay.css';
import { translationContext } from '../../App';
import { HotelContext } from '../../contexts/HotelProvider';
import Autocomplete from '../AutoComplete/AutoComplete';
import { urlParamsContext } from '../../App';
import { initialFilterOptions } from '../../utils/initialFilterOptions';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

function FiltersOverlay({ setFiltersOverlayOpen }) {
  /* Start Context */
  const translations = React.useContext(translationContext);
  const { allHotels, setFilters, setFilterCount, setIsLoading, filteredHotels, hotelNameFilter } = useContext(HotelContext);
  const urlParams = React.useContext(urlParamsContext);
  /* End Context */

  /* Start State */
  const dictionary = translations.translations;
  const Unlimited = dictionary.find((translation) => translation.code === 'Unlimited').text;
  const [searchQuery, setSearchQuery] = useState('');
  const [matchingHotelNames, setMatchingHotelNames] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRadius, setSelectedRadius] = useState(urlParams.radius);
  const [isSpinner, setShowSpinner] = useState(false);
  /* Start useEffect */
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // Close the autocomplete
        setIsDropdownOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedRadius(Number(urlParams.radius));
  }, [urlParams.radius]);

  useEffect(() => {
    //Check if any filters are selected
    const isFilterSelected =
      urlParams.filterOptions.petsAllowed ||
      urlParams.filterOptions.adaAccomodations ||
      urlParams.filterOptions.mealsProvided ||
      urlParams.filterOptions.hotelNames.length > 0;

    urlParams.setFiltersInUse(isFilterSelected);
    setFilters(urlParams.filterOptions);
  }, [urlParams.filterOptions]);

  useEffect(() => {
    if (searchQuery === '' && inputFocused) {
      // show first 5 unique hotels by default if search query is empty
      const uniqueHotelNames = hotelNameFilter
        .filter((hotel) => !urlParams.filterOptions.hotelNames.includes(cleanHotelName(hotel.Name)))
        .map((hotel) => cleanHotelName(hotel.Name))
        .filter((value, index, self) => self.indexOf(value) === index);

      setMatchingHotelNames(uniqueHotelNames.slice(0, 5));
    } else {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      const matches = hotelNameFilter
        .filter((hotel) => hotel.Name && cleanHotelName(hotel.Name).toLowerCase().includes(lowerCaseSearchQuery))
        .filter((hotel) => !urlParams.filterOptions.hotelNames.includes(cleanHotelName(hotel.Name)))
        .map((hotel) => cleanHotelName(hotel.Name));
      // convert matches array to Set to eliminate duplicates, then convert it back to array
      setMatchingHotelNames([...new Set(matches)]);
    }
  }, [searchQuery, filteredHotels, inputFocused, urlParams.filterOptions.hotelNames]);
  /* End useEffect */

  /* Start Helper Functions */
  const cleanHotelName = (name) => name.replace(/\s*\([^)]*\)\s*/g, '');
  const handleFocus = () => {
    setInputFocused(true);
    setIsDropdownOpen(true); // set dropdown to open
  };
  const wrapperRef = useRef(null);
  const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && matchingHotelNames.length) {
      event.preventDefault();
      const selectedHotel = matchingHotelNames[0]; // select the first hotel from the suggestions
      urlParams.setFilterOptions((prevOptions) => ({
        ...prevOptions,
        hotelNames: [...prevOptions.hotelNames, selectedHotel],
      }));
      await delay(250);
      setSearchQuery(''); // clear the search query
    }
  };
  const handleInputChange = async (event) => {
    setShowSpinner(true);
    const { value } = event.target;
    setSearchQuery(value);

  };

  const handleClose = () => {
    setFiltersOverlayOpen(false);
  };

  const handleRadiusChange = async (event) => {
    setShowSpinner(true);
    urlParams.setRadius(Number(event.target.value));
    await delay(500);
    setShowSpinner(false);
    setIsLoading(false);
  };

  const handleCheckboxChange = async (event) => {
    setShowSpinner(true);
    setIsLoading(true);
    urlParams.setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [event.target.name]: event.target.checked,
    }));
    await delay(500);
    setShowSpinner(false);
    setIsLoading(false);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleReset = async () => {
    urlParams.setFilterOptions(initialFilterOptions);
    urlParams.setFiltersInUse(false);
    setFilterCount(0);
    urlParams.setIsAdaAccomodations(false);
    urlParams.setIsPetsAllowed(false);
    urlParams.setIsMealsProvided(false);
    urlParams.setSelectedFilterHotelNames([]);
    urlParams.setFilterOptions(initialFilterOptions);
    await delay(500);
  };
  /* End Helper Functions */

  return (
    <div className="filters-overlay-wrap">
      <div className="filters-overlay">
        <div className="filters-overlay-header">
          <div className="filters-overlay-header-title">
            {dictionary.find((translation) => translation.code === 'Filters').text}
          </div>
          <button className="filters-overlay-header-close" onClick={handleClose}>
            <span className="material-symbols-rounded">close</span>
          </button>
        </div>
        <div className="filters-overlay-body">
          <SimpleBar>
            <div className="filters-overlay-section">
              <fieldset className="filters-overlay-fieldset">
                <legend className="filters-overlay-section-title">
                  {dictionary.find((translation) => translation.code === 'Hotels-Within').text}
                  <span className="filters-overlay-section-title-light">
                    {' '}
                    ({dictionary.find((translation) => translation.code === 'Miles').text})
                  </span>
                </legend>
                <div className="filters-overlay-input-wrap">
                  <label className="filters-overlay-radio" htmlFor="mi25">
                    <input
                      className="filters-overlay-input"
                      type="radio"
                      id="mi25"
                      name="mile-radius"
                      value={25}
                      onChange={handleRadiusChange}
                      checked={selectedRadius === 25}
                    />
                    <span className="filters-overlay-text">25 mi</span>
                  </label>
                  <label className="filters-overlay-radio" htmlFor="mi50">
                    <input
                      className="filters-overlay-input"
                      type="radio"
                      id="mi50"
                      value={50}
                      name="mile-radius"
                      onChange={handleRadiusChange}
                      checked={selectedRadius === 50}
                    />
                    <span className="filters-overlay-text">50 mi</span>
                  </label>
                  <label className="filters-overlay-radio" htmlFor="mi100">
                    <input
                      className="filters-overlay-input"
                      type="radio"
                      id="mi100"
                      name="mile-radius"
                      onChange={handleRadiusChange}
                      value={100}
                      checked={selectedRadius === 100}
                    />
                    <span className="filters-overlay-text">100 mi</span>
                  </label>
                  {selectedRadius > 100 && (
                    <label className="filters-overlay-radio" htmlFor="miInf">
                      <input
                        className="filters-overlay-input"
                        type="radio"
                        id="miInf"
                        name="mile-radius"
                        onChange={handleRadiusChange}
                        value={10000}
                        checked={selectedRadius > 100}
                      />
                      <span className="filters-overlay-text">
                        {urlParams.radius === 10000 ? Unlimited : urlParams.radius + ' mi'}
                      </span>
                    </label>
                  )}
                </div>
              </fieldset>
            </div>
            <div className="filters-overlay-section">
              <p className="filters-overlay-section-title">
                {dictionary.find((translation) => translation.code === 'Pets-Allowed').text}
              </p>
              <label className="filters-overlay-inline-label">
                <input
                  className="filters-overlay-inline-input"
                  type="checkbox"
                  name="petsAllowed"
                  checked={urlParams.filterOptions.petsAllowed}
                  onChange={handleCheckboxChange}
                />
                <span className="filters-overlay-inline-checkbox">&nbsp;</span>
                <span className="filters-overlay-inline-text">
                  {dictionary.find((translation) => translation.code === 'Filter-Pets-Text').text}
                </span>
              </label>
            </div>
            <div className="filters-overlay-section">
              <p className="filters-overlay-section-title">
                {dictionary.find((translation) => translation.code === 'ADA-Accommodations').text}
              </p>
              <label className="filters-overlay-inline-label">
                <input
                  className="filters-overlay-inline-input"
                  type="checkbox"
                  name="adaAccomodations"
                  checked={urlParams.filterOptions.adaAccomodations}
                  onChange={handleCheckboxChange}
                />
                <span className="filters-overlay-inline-checkbox">&nbsp;</span>
                <span className="filters-overlay-inline-text">
                  {dictionary.find((translation) => translation.code === 'Filter-ADA-Text').text}
                </span>
              </label>
            </div>
            <div className="filters-overlay-section">
              <p className="filters-overlay-section-title">
                {dictionary.find((translation) => translation.code === 'Meals-Provided').text}
              </p>
              <label className="filters-overlay-inline-label">
                <input
                  className="filters-overlay-inline-input"
                  type="checkbox"
                  name="mealsProvided"
                  checked={urlParams.filterOptions.mealsProvided}
                  onChange={handleCheckboxChange}
                />
                <span className="filters-overlay-inline-checkbox">&nbsp;</span>
                <span className="filters-overlay-inline-text">
                  {dictionary.find((translation) => translation.code === 'Filter-Kitchenettes-Text').text}
                </span>
              </label>
            </div>
            <div className="filters-overlay-section autocomplete-section">
              <legend className="filters-overlay-section-title">
                {dictionary.find((translation) => translation.code === 'Hotel-Name').text}
              </legend>
              <div className="filters-overlay-search-wrap" ref={wrapperRef}>
                <label className="filters-overlay-search-label" htmlFor="hotel-chain-search">
                  {dictionary.find((translation) => translation.code === 'Search-Hotels').text}
                </label>
                <input
                  className="filters-overlay-search-input"
                  type="search"
                  id="hotel-chain-search"
                  placeholder={dictionary.find((translation) => translation.code === 'Search-Hotels').text}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onFocus={handleFocus}
                  // onBlur={handleBlur}
                  value={searchQuery}
                />
                {isDropdownOpen && (
                  <Autocomplete
                    suggestions={matchingHotelNames}
                    onSuggestionClick={(suggestion) => {
                      urlParams.setFilterOptions((prevOptions) => ({
                        ...prevOptions,
                        hotelNames: [...prevOptions.hotelNames, suggestion],
                      }));
                      setSearchQuery(''); // clear the search query
                      setIsDropdownOpen(false); // close dropdown
                    }}
                  />
                )}
                <button className="filters-overlay-search-submit">
                  <span className="material-symbols-rounded">search</span>
                </button>
              </div>
              {/* hotel chips */}
              <div className="filters-overlay-chip-wrap">
                {urlParams.filterOptions.hotelNames.map((hotelName, index) => (
                  <div className="filters-overlay-chip" key={index}>
                    {hotelName}
                    <button
                      className="filters-overlay-chip-remove"
                      title="remove"
                      onClick={() => {
                        urlParams.setFilterOptions((prevOptions) => ({
                          ...prevOptions,
                          hotelNames: prevOptions.hotelNames.filter((name) => name !== hotelName),
                        }));
                      }}
                    >
                      <span className="material-symbols-rounded" aria-hidden="true">
                        close
                      </span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </SimpleBar>
        </div>
        <div className="filters-overlay-footer">
          {urlParams.filtersInUse ? (
            <button className="filters-overlay-footer-button" onClick={handleReset}>
              {dictionary.find((translation) => translation.code === 'Reset-All').text}
            </button>
          ) : (
            ''
          )}
          <button className="filters-overlay-footer-button power" onClick={handleClose}>
            {isSpinner ? (
              <span className="button-spinner">Loading</span>
            ) : (
              <>
                {dictionary.find((translation) => translation.code === 'Show').text} {filteredHotels.length}{' '}
                {filteredHotels?.length == 1
                  ? dictionary.find((translation) => translation.code === 'Result').text
                  : dictionary.find((translation) => translation.code === 'Results').text}
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FiltersOverlay;
