import React from 'react';
import { translationContext } from '../../App';
import { ViewedHotelsContext } from '../../contexts/ViewedHotelsProvider';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import bed from '../../assets/bed.png'
function ResultsListItem({
  name,
  address,
  address2,
  city,
  state,
  zip,
  phone,
  ada,
  ada_comments,
  kitchen,
  kitchen_comments,
  pet,
  pets_comments,
  images,
  distance,
  openOverlay,
  index,
  onMouseEnter,
  onMouseLeave,
  trackCallClick
}) {
  const translations = React.useContext(translationContext);
  const dictionary = translations.translations;
  const MoreDetails = dictionary.find((translation) => translation.code === 'More-Details').text;
  const Phone = dictionary.find((translation) => translation.code === 'Phone').text;
  const PetsAllowed = dictionary.find((translation) => translation.code === 'Pets-Allowed').text;
  const PetsNotAllowed = dictionary.find((translation) => translation.code === 'Pets-Not-Allowed').text;
  const ADAAccommodations = dictionary.find((translation) => translation.code === 'ADA-Accommodations').text;
  const NoADAAccommodations = dictionary.find((translation) => translation.code === 'No-ADA-Accommodations').text;
  const MealsProvided = dictionary.find((translation) => translation.code === 'Meals-Provided').text;
  const NoMealsProvided = dictionary.find((translation) => translation.code === 'No-Meals-Provided').text;
  const Viewed = dictionary.find((translation) => translation.code === 'Viewed').text;
  const Called = dictionary.find((translation) => translation.code === 'Called').text;
  const handlePhoneNumberClick = (e) => {
    e.stopPropagation();
    addCalledHotel(index);
    trackCallClick();
  };
  const { viewedHotels, addViewedHotel } = React.useContext(ViewedHotelsContext);
  const { calledHotels, addCalledHotel } = React.useContext(ViewedHotelsContext);
  const imageThumbnail = 
  images && 
  images.ImageURL.length > 0 && 
  !images.ImageURL[0].endsWith("no_hotel_results.jpg") ? 
    images.ImageURL[0] : 
    bed;
  const imageClass = 
  images && 
  images.ImageURL.length > 0 && 
  !images.ImageURL[0].endsWith("no_hotel_results.jpg") ? '' : 'bed';
  const HeaderTitle = ({ name }) => {
    // Split the name based on the '(' character
    const [hotelName, hotelNumberWithBracket] = name.split('(');

    // Remove the closing bracket ')' from the hotelNumber
    const hotelNumber = hotelNumberWithBracket.replace(')', '');

    return (
      <span className="results-list-item-name">
        <span className="name">{hotelName.trim()} </span>
        <span className="number">({hotelNumber})</span>
      </span>
    );
  };
  const viewed = viewedHotels.includes(index);
  const called = calledHotels.includes(index);

  const handleClick = () => {
    openOverlay();
    addViewedHotel(index);
    // Add any other logic you want to occur on click here
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div
      className="results-list-item"
      onClick={handleClick}
      onKeyDown={handleEnter}
      tabIndex="0"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className="results-list-item-left">
        <span className={"results-list-item-image " + imageClass}>
          <img src={imageThumbnail} alt={name} width="137" height="113" loading="lazy" />
          {/* TODO: Wire up if location has been viewed and called. Only show 1 flag max. */}

          {called && <span className="results-list-item-flag loud">{Called}</span>}
          {viewed && <span className="results-list-item-flag">{Viewed}</span>}
        </span>
        <span className="results-list-item-info">
          <span className="results-list-item-name">
            <HeaderTitle name={name} />
          </span>
          <span className="results-list-item-address">
            {address} <br />
            {address2}
            {address2 !== '' && <br />}
            {city}, {state} {zip} <br />
            <a
              className="results-list-item-phone"
              href={'tel:+1-' + phone} // format should be (xxx) xxx - xxxx
              onClick={handlePhoneNumberClick}
            >
              {Phone} {formatPhoneNumber(phone)}
            </a>
          </span>
        </span>
      </span>
      <span className="results-list-item-right">
        <span className="results-list-item-feature-list">
          <span className="results-list-item-feature">
            <span
              className={
                pet === 'Y'
                  ? 'results-list-item-feature-icon material-symbols-rounded green'
                  : 'results-list-item-feature-icon material-symbols-rounded'
              }
              aria-hidden="true"
            >
              {pet === 'Y' ? 'check_circle' : 'block'}
            </span>
            <span className="results-list-item-feature-name">
              {pet === 'Y' ? PetsAllowed : PetsNotAllowed}
              {pets_comments !== '' && pets_comments.toLowerCase() !== 'null' && <br />}
              {pets_comments.toLowerCase() !== 'null' && pets_comments}
            </span>
          </span>
          <span className="results-list-item-feature">
            <span
              className={
                ada === 'Y'
                  ? 'results-list-item-feature-icon material-symbols-rounded green'
                  : 'results-list-item-feature-icon material-symbols-rounded'
              }
              aria-hidden="true"
            >
              {ada === 'Y' ? 'check_circle' : 'block'}
            </span>
            {ada === 'Y' && (
              <span className="results-list-item-feature-name">
                {ADAAccommodations} {ada_comments !== '' && ada_comments.toLowerCase() !== 'null' && <br />}
                {ada_comments.toLowerCase() !== 'null' && ada_comments}
              </span>
            )}
            {ada === 'N' && (
              <span className="results-list-item-feature-name">
                {NoADAAccommodations} <br />
              </span>
            )}
          </span>
          <span className="results-list-item-feature">
            <span
              className={
                kitchen === 'Y'
                  ? 'results-list-item-feature-icon material-symbols-rounded green'
                  : 'results-list-item-feature-icon material-symbols-rounded'
              }
              aria-hidden="true"
            >
              {kitchen === 'Y' ? 'check_circle' : 'block'}
            </span>
            <span className="results-list-item-feature-name">
              {kitchen === 'Y' ? MealsProvided : NoMealsProvided}
              {kitchen_comments !== '' && kitchen_comments.toLowerCase() !== 'null' && <br />}
              {kitchen_comments.toLowerCase() !== 'null' && kitchen_comments}
            </span>
          </span>
        </span>
        <span className="results-list-item-distance">{distance} mi</span>
      </span>
    </div>
  );
}

export default ResultsListItem;
