import React, { createContext, useRef, useState } from 'react';

export const RefreshTimerContext = createContext();

export const RefreshTimerProvider = ({ children }) => {
  const refreshTimer = useRef(null);
  const [refreshOverlayOpen, setRefreshOverlayOpen] = useState(false);
  const timeLimit = 60 * 60 * 1000; // 60 Minutes

  const startTimer = () => {
    const timer = setInterval(() => {
      const storedTimeStamp = sessionStorage.getItem('resultsCallTimeStamp');

      if (storedTimeStamp) {
        const storedTime = new Date(storedTimeStamp);
        const currentTime = new Date();
        const oneHourBeforeCurrentTime = new Date(currentTime.getTime() - timeLimit);

        if (storedTime <= oneHourBeforeCurrentTime) {
          setRefreshOverlayOpen(true);
        }
      }
    }, 60 * 1000); //check every minute
    refreshTimer.current = timer;
  };

  const resetTimer = () => {
    clearTimeout(refreshTimer.current);
  };

  const handleResetTimer = () => {
    if (refreshTimer.current) {
      resetTimer();
    }

    startTimer();
  };

  return (
    <RefreshTimerContext.Provider value={{ handleResetTimer, refreshOverlayOpen, setRefreshOverlayOpen }}>
      {children}
    </RefreshTimerContext.Provider>
  );
};

