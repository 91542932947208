import React, { useState, useEffect, useContext } from 'react';
import './Find.css';
import Navigation from '../../components/Navigation/Navigation';
import BackgroundImage from '../../assets/background.jpg';
import Logo from '../../assets/FEMA-logo-blue.png';
import LocationSearchInput from '../../components/LocationSearchInput/LocationSearchInput';
import { urlParamsContext } from '../../App';
import { translationContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import { HotelContext } from '../../contexts/HotelProvider';
import { initialFilterOptions } from '../../utils/initialFilterOptions';
function Results() {
  const translations = React.useContext(translationContext);
  const dictionary = translations.translations;
  const urlParams = React.useContext(urlParamsContext);
  const FindAPlaceToStay = dictionary.find((translation) => translation.code === 'Place-To-Stay').text;
  const TSA = dictionary.find((translation) => translation.code === 'TSA').text;
  const HotelsWithin = dictionary.find((translation) => translation.code === 'Hotels-Within').text;
  const ShowResults = dictionary.find((translation) => translation.code === 'Show-Results').text;
  const PetsAllowed = dictionary.find((translation) => translation.code === 'Pets-Allowed').text;
  const FilterPetsText = dictionary.find((translation) => translation.code === 'Filter-Pets-Text').text;
  const ADAAccommodations = dictionary.find((translation) => translation.code === 'ADA-Accommodations').text;
  const FilterADAText = dictionary.find((translation) => translation.code === 'Filter-ADA-Text').text;
  const Kitchenette = dictionary.find((translation) => translation.code === 'Kitchenette').text;
  const FilterKitchenettesText = dictionary.find((translation) => translation.code === 'Filter-Kitchenettes-Text').text;

  const language = translations.language;
  const setLanguage = translations.dictionaryDispatch;
  const setRadiusState = urlParams.setRadius;
  const [englishLabel, setEnglishLabel] = useState(language);
  const [userLanguage, setUserLanguage] = useState('');
  const [spanishLabel, setSpanishLabel] = useState('');
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const navigate = useNavigate();
  const { setFilters } = useContext(HotelContext);
  const [filterOptions, setFilterOptions] = useState(initialFilterOptions);
  const [anyFilterSelected, setAnyFilterSelected] = useState(false);
  const [searchValue, _setSearchValue] = useState('');
  document.title = `${FindAPlaceToStay} | ${TSA}`;

  function setSearchValue(value) {
    _setSearchValue(value);
    urlParams.setSearch(value);
  }

  function setLanguageLabels(outerWidth) {
    if (outerWidth < 750) {
      setEnglishLabel('EN');
      setSpanishLabel('ES');
    } else {
      setEnglishLabel('English');
      setSpanishLabel('Español');
    }
  }

  useEffect(() => {
    // Function to handle browser back button click
    const handleBrowserBack = () => {
      navigate('/'); // Go back to the previous page
    };

    // Add event listener for browser back button click
    window.addEventListener('popstate', handleBrowserBack);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('popstate', handleBrowserBack);
    };
  }, [navigate]); // Empty dependency array to run the effect only once

  useEffect(() => {
    const regNumCookie = document.cookie.replace(/(?:(?:^|.*;\s*)regNum\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    //If RegNum is not set, redirect to registration page
    if (
      (regNumCookie === undefined || regNumCookie === '' || regNumCookie === null) &&
      (urlParams.regNum === undefined || urlParams.regNum === '' || urlParams.regNum === null)
    ) {
      window.location.href = '/';
      return;
    }

    const handleWindowResize = () => {
      setWindowSize([window.outerWidth, window.outerHeight]);
      setLanguageLabels(window.outerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    setLanguageLabels(window.outerWidth);

    //Setting defaults
    if (urlParams.search === undefined || urlParams.search === null || urlParams.search === '') {
      urlParams.setLatitude(undefined);
      urlParams.setLongitude(undefined);
      urlParams.setLocationSelected(false);

    }

    setSearchValue('');
    urlParams.setSearch('');
    urlParams.setRadius(25);
    urlParams.setIsPetsAllowed(false);
    urlParams.setIsAdaAccomodations(false);
    urlParams.setIsMealsProvided(false);
    if (urlParams.latitude === undefined || urlParams.longitude === undefined) {
      urlParams.setSearch('');
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  async function handleEnterKeyPress(event) {
    const { keyCode } = event;
    let showError = false;
    if (keyCode === 13 && urlParams.locationSelected) {
      if (urlParams.latitude !== undefined && urlParams.longitude !== undefined) {
        sendToResults();
      } else {
        showError = true;
      }
      urlParams.setShowError(showError);
    }
  }

  useEffect(() => {
    setSearchValue(urlParams.search);
   // urlParams.setLocationSelected(true);
  }, [urlParams.search]);

  useEffect(() => {
    if (urlParams.locationSelected === true) {
      window.addEventListener('keydown', handleEnterKeyPress);
    }
    return () => {
      window.removeEventListener('keydown', handleEnterKeyPress);
    };
  }, [urlParams.locationSelected, urlParams.latitude, urlParams.longitude]);

  //Get user language from browser
  useEffect(() => {
    if (userLanguage === '') {
      let userLang = navigator.language || navigator.userLanguage;
      userLang = userLang.substring(0, 2);
      if (userLang !== 'en' && userLang !== 'es') {
        userLang = 'en';
      }
      setUserLanguage(userLang);
      setLanguageLabels(window.outerWidth);
    }
  }, [userLanguage]);

  function sendToResultsCheck (){
    if (!urlParams.locationSelected) {
      if (
        searchValue === undefined ||
        searchValue === null ||
        searchValue === '' ||
        searchValue?.replace(/\s+/g, '') === ''
      ) {
        urlParams.setShowError(true);
      }
     urlParams.setShowError(true);}
    else{
      sendToResults();
    }
  }

  async function sendToResults() {
    let toResults = true;
    if (!urlParams.locationSelected) {
      if (
        searchValue === undefined ||
        searchValue === null ||
        searchValue === '' ||
        searchValue?.replace(/\s+/g, '') === ''
      ) {
        toResults = false;
      }
     // urlParams.setShowError(true);
      toResults = false;
    } else {
      if (toResults) {
        if (urlParams.radius === undefined || urlParams.radius === null || urlParams.radius === '') {
          setRadiusState(25);
        }
        const { petsAllowed, adaAccomodations, mealsProvided } = filterOptions;
        const anyFilterSelected = petsAllowed || adaAccomodations || mealsProvided;

        if (petsAllowed) {
          urlParams.setIsPetsAllowed(true);
        } else {
          urlParams.setIsPetsAllowed(false);
        }
        if (adaAccomodations) {
          urlParams.setIsAdaAccomodations(true);
        } else {
          urlParams.setIsAdaAccomodations(false);
        }
        if (mealsProvided) {
          urlParams.setIsMealsProvided(true);
        } else {
          urlParams.setIsMealsProvided(false);
        }
        filterOptions.radius = urlParams.radius;
        urlParams.setFiltersInUse(anyFilterSelected);
        setFilters(filterOptions);
        navigate(`/results?${urlParams.completeUrlString}`);
      }
    }
  }

  const handleCheckboxChange = (event) => {
    urlParams.setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <div className="find-wrap">
      <div className="find-background">
        <img className="find-background-image" src={BackgroundImage} alt="hotel room key" />
      </div>
      <Navigation
        userLanguage={language}
        setUserLanguage={setLanguage}
        englishLabel={englishLabel}
        spanishLabel={spanishLabel}
      />
      <div className="find-panel">
        <div className="find-panel-branding" data-lang={language}>
          <a className="find-panel-branding-logo" href="/">
            <img className="find-panel-branding-image" src={Logo} alt="FEMA logo" />
          </a>
          <p className="find-panel-banding-text" data-lang={language}>
            {TSA}
          </p>
        </div>
        <form
          className="find-panel-form"
          onSubmit={(e) => {
            setTimeout(sendToResults, 200);
            e.preventDefault();
          }}
        >
          <div className="find-panel-form-section">
            <h1 className="find-panel-form-title">{FindAPlaceToStay}</h1>
            <div className="find-panel-form-search-wrap">
              <LocationSearchInput setLatitude={urlParams.setLatitude} setLongitude={urlParams.setLongitude} />
            </div>
            <fieldset className="find-panel-radio-wrap">
              <legend className="find-panel-radio-legend">{HotelsWithin}</legend>
              <div className="find-panel-radio-flex">
                <label className="find-panel-radio" htmlFor="mi25">
                  <input
                    className="find-panel-radio-input"
                    type="radio"
                    id="mi25"
                    name="mile-radius"
                    onClick={() => setRadiusState(25)}
                    defaultChecked
                  />
                  <span className="find-panel-radio-text">25 mi</span>
                </label>
                <label className="find-panel-radio" htmlFor="mi50">
                  <input
                    className="find-panel-radio-input"
                    type="radio"
                    id="mi50"
                    name="mile-radius"
                    onClick={() => setRadiusState(50)}
                  />
                  <span className="find-panel-radio-text">50 mi</span>
                </label>
                <label className="find-panel-radio" htmlFor="mi100">
                  <input
                    className="find-panel-radio-input"
                    type="radio"
                    id="mi100"
                    name="mile-radius"
                    onClick={() => setRadiusState(100)}
                  />
                  <span className="find-panel-radio-text">100 mi</span>
                </label>
              </div>
            </fieldset>
          </div>
          <div className="find-panel-form-section">
            <p className="find-panel-form-section-title">{PetsAllowed}</p>
            <label className="find-panel-form-label" htmlFor="pets-allowed">
              <input
                className="find-panel-form-checkbox"
                type="checkbox"
                id="pets-allowed"
                name="petsAllowed"
                checked={urlParams.filterOptions.petsAllowed}
                onChange={handleCheckboxChange}
              />
              <span className="find-panel-form-checkbox-icon">&nbsp;</span>
              <span className="find-panel-form-checkbox-text">{FilterPetsText}</span>
            </label>
          </div>
          <div className="find-panel-form-section">
            <p className="find-panel-form-section-title">{ADAAccommodations}</p>
            <label className="find-panel-form-label" htmlFor="ada-accomodations">
              <input
                className="find-panel-form-checkbox"
                type="checkbox"
                id="ada-accomodations"
                name="adaAccomodations"
                checked={urlParams.filterOptions.adaAccomodations}
                onChange={handleCheckboxChange}
              />
              <span className="find-panel-form-checkbox-icon">&nbsp;</span>
              <span className="find-panel-form-checkbox-text">{FilterADAText}</span>
            </label>
          </div>
          <div className="find-panel-form-section">
            <p className="find-panel-form-section-title">{Kitchenette}</p>
            <label className="find-panel-form-label" htmlFor="kitchenette">
              <input
                className="find-panel-form-checkbox"
                type="checkbox"
                id="kitchenette"
                name="mealsProvided"
                checked={urlParams.filterOptions.mealsProvided}
                onChange={handleCheckboxChange}
              />
              <span className="find-panel-form-checkbox-icon">&nbsp;</span>
              <span className="find-panel-form-checkbox-text">{FilterKitchenettesText}</span>
            </label>
          </div>
          <button className="find-panel-form-button" type="submit" onClick={sendToResultsCheck} >
            {ShowResults}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Results;
