import React, { useContext, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
import './ErrorOverlay.css';
import { translationContext } from '../../App';

function CookieOverlay({ setCookieOverlayOpen }) {
  const localTranslationContext = useContext(translationContext);
  const dictionary = localTranslationContext.translations;

  const CookiePolicy = dictionary.find((translation) => translation.code === 'Cookie-Policy').text;
  const CookiePolicyText = dictionary.find((translation) => translation.code === 'Cookie-Policy-Text').text;
  const overlayRootRef = useRef(null);
  const closeButtonRef = useRef(null);

  const handleCloseOnEnter = (event) => {
    // Check if 'Enter' was pressed
    if (event.key === 'Enter') {
      setCookieOverlayOpen(false)
    }
  };

  useEffect(() => {
    const overlayRoot = overlayRootRef.current;
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const focusableContent = Array.from(overlayRoot.querySelectorAll(focusableElements));
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    const trapFocus = (event) => {
      if (document.activeElement === firstFocusableElement && event.shiftKey && event.key === 'Tab') {
        event.preventDefault();
        lastFocusableElement.focus();
      } else if (document.activeElement === lastFocusableElement && !event.shiftKey && event.key === 'Tab') {
        event.preventDefault();
        firstFocusableElement.focus();
      }
    };

    overlayRoot.addEventListener('keydown', trapFocus);
    // focus on closebutton initially
    closeButtonRef.current.focus();

    return () => overlayRoot.removeEventListener('keydown', trapFocus);
  }, []);

  return (
    <div  ref={overlayRootRef} className="cookie-overlay-wrap">
      <div className="error-overlay">
        <a className="error-overlay-close cookie-overlay-close" onClick={() => setCookieOverlayOpen(false)}  ref={closeButtonRef} tabIndex="0" onKeyDown={handleCloseOnEnter}>
          <span className="material-symbols-rounded">close</span>
        </a>
        <p className="error-overlay-headline">{CookiePolicy}</p>
        <p className="error-overlay-text wide">{CookiePolicyText}</p>
      </div>
    </div>
  );
}
export default CookieOverlay;
