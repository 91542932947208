import React, { useState, useReducer, useEffect } from 'react';
import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Registration from './pages/Registration/Registration';
import Find from './pages/Find/Find';
import apiAdress from './utils/apiAddress';
import Results from './pages/Results/Results';
import Help from './pages/Help/Help';
import NotFound from './pages/NotFound/NotFound';
import { FetchTranslationsByLanguage } from './services/translation';
import { RefreshTimerProvider } from './contexts/RefreshTimerContext';
import { ErrorProvider } from './contexts/ErrorContext';
import { HotelProvider } from './contexts/HotelProvider';
import { initialFilterOptions } from './utils/initialFilterOptions';
export const urlParamsContext = React.createContext();
export const translationContext = React.createContext();
import Loading from './components/Loading/Loading';

function App() {
  //Get URL Parameters for regNum, radius, and address
  const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
  const radius = Number(urlParams.get('radius'));
  const search = urlParams.get('search');
  const isPetFriendly = urlParams.get('isPetFriendly');
  const adaAccomodations = urlParams.get('adaAccomodations');
  const isKitchen = urlParams.get('isKitchen');
  const hotelNames = urlParams.get('HotelNames');
  const regNumURL = urlParams.get('regNum');
  const regNumCookie = document.cookie.replace(/(?:(?:^|.*;\s*)regNum\s*\=\s*([^;]*).*$)|^.*$/, '$1');
  const languageCookie = document.cookie.replace(/(?:(?:^|.*;\s*)language\s*\=\s*([^;]*).*$)|^.*$/, '$1');
  const returnURL = document.cookie.replace(/(?:(?:^|.*;\s*)returnURL\s*\=\s*([^;]*).*$)|^.*$/, '$1');
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [radiusState, setRadiusState] = useState();
  const [searchState, setSearchState] = useState();
  const [selected, setSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [errorOverlayOpen, setErrorOverlayOpen] = useState(false);
  const [regNum, _setRegNum] = useState();
  const [versionCounter, setVersionCounter] = useState(0);
  const [userLanguage, _setUserLanguage] = useState('init');
  const [isAdaAccomodations, setIsAdaAccomodations] = useState(false);
  const [isMealsProvided, setIsMealsProvided] = useState(false);
  const [isPetsAllowed, setIsPetsAllowed] = useState(false);
  const [selectedFilterHotelNames, setSelectedFilterHotelNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState(initialFilterOptions);
  const [filtersInUse, setFiltersInUse] = useState(false);
  const [isGeo, setIsGeo] = useState(false);
  const [locationSelected, setLocationSelected] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(false);
  const [completeUrlString, setUrlString] = useState("");
  const setRegNum = (regNum) => {
    if (regNum !== null && regNum !== undefined) {
      _setRegNum(regNum);
      document.cookie = 'regNum=' + regNum + '; path=/';
    }
  };

  const setUserLanguage = (lang) => {
    if (lang !== null && lang !== undefined) {
      _setUserLanguage(lang);
      document.cookie = 'language=' + lang + '; path=/';
    }
  };

  const [dictionary, setDictionary] = useState(FetchTranslationsByLanguage('en'));

  const parameters = {};

  for (let param of urlParams) {
    parameters[param[0]] = param[1];
  }

  useEffect(() => {
    //Set regNum
    const registrationNumber =
      regNumCookie !== 'null' && regNumCookie !== null && regNumCookie !== '' && regNumCookie !== undefined
        ? regNumCookie
        : regNumURL;
    setRegNum(registrationNumber);

    let userLang = navigator.language || navigator.userLanguage;
    let startingLanguage =
      languageCookie !== undefined && languageCookie !== null && languageCookie !== '' && languageCookie !== 'null'
        ? languageCookie
        : userLang.substring(0, 2);
    setUserLanguage(startingLanguage);

    //Set returnURL
    if (
      (registrationNumber === undefined || registrationNumber === null) &&
      window.location.href.indexOf('/results?search=') !== -1
    ) {
      //Store current URL in cookie
      document.cookie = 'returnURL=' + window.location.href + '; path=/';
    }

    //Set radius
    if (radius === null || radius === '' || radius === undefined) {
      setRadiusState(25);
    } else {
      if (radius === 10000) {
        setRadiusState(10000);
      } else if (radius === 6000) {
        setRadiusState(6000);
      } else if (radius === 1000) {
        setRadiusState(1000);
      } else if (radius === 500) {
        setRadiusState(500);
      } else if (radius === 250) {
        setRadiusState(250);
      } else if (radius >= 100) {
        setRadiusState(100);
      } else if (radius >= 50 && radius < 100) {
        setRadiusState(50);
      } else if (radius >= 25 && radius < 50) {
        setRadiusState(25);
      } else {
        setRadiusState(25);
      }
    }

    let tempFilterInUse = false;
    //Set search
    if (search === null || search === '' || search === undefined) {
      setSearchState('');
    } else {
      setSearchState(search);
    }
    let filterFromUrl = {};
    if (isPetFriendly) {
      filterFromUrl.petsAllowed = isPetFriendly === 'true';
      setIsPetsAllowed(filterFromUrl.petsAllowed);
    }
    if (adaAccomodations) {
      filterFromUrl.adaAccomodations = adaAccomodations === 'true';
      setIsAdaAccomodations(filterFromUrl.adaAccomodations);
    }
    if (isKitchen) {
      filterFromUrl.mealsProvided = isKitchen === 'true';
      setIsMealsProvided(filterFromUrl.mealsProvided);
    }
    if (hotelNames) {
      filterFromUrl.hotelNames = hotelNames.split(',');
      setSelectedFilterHotelNames(filterFromUrl.hotelNames);
    }

    tempFilterInUse = [
      filterFromUrl.petsAllowed,
      filterFromUrl.adaAccomodations,
      filterFromUrl.mealsProvided,
      filterFromUrl.hotelNames && filterFromUrl.hotelNames.length > 0,
    ].some((value) => value === true);

    filterFromUrl.radius = radius;
    filterFromUrl.searchTerm = search;
    setFilterOptions((prev) => ({ ...prev, ...filterFromUrl }));
    setFiltersInUse(tempFilterInUse);

  }, []);

  useEffect(() => {
    // Update URL Parameters
    let urlString = '';
    let filtersInUse = false;
    if (searchState !== null && searchState !== '' && searchState !== undefined) {
      urlString += '?search=' + searchState;

      if (radiusState !== null && radiusState !== '' && radiusState !== undefined) {
        urlString += '&radius=' + radiusState;
      }

      // Add parameters based on conditions
      if (filterOptions.petsAllowed) {
        urlString += '&isPetFriendly=true';
        setIsPetsAllowed(true);
        filtersInUse = true;
      }

      if (filterOptions.adaAccomodations) {
        urlString += '&adaAccomodations=true';
        setIsAdaAccomodations(true);
        filtersInUse = true;
      }

      if (filterOptions.mealsProvided) {
        urlString += '&isKitchen=true';
        setIsMealsProvided(true);
        filtersInUse = true;
      }

      if (filterOptions.hotelNames.length > 0) {
        urlString += '&HotelNames=' + filterOptions.hotelNames.join(',');
        setSelectedFilterHotelNames(filterOptions.hotelNames);
        filtersInUse = true;
      }
    } else {
      // If search was cleared then remove search from URL
      let urlString2 = window.location.href.split('?')[0];
      window.history.pushState(null, null, urlString2);
    }

    if (urlString !== '') {
      urlString = window.location.href.split('?')[0] + urlString;
      window.history.pushState(null, null, urlString);
    }
    const fullUrlString = urlString.split("?")[1];
    setUrlString(fullUrlString);
    setFiltersInUse(filtersInUse);
  }, [radiusState, searchState, filterOptions]);

  const reducer = (state, action) => {
    switch (action) {
      case 'en':
        setUserLanguage('en');
        break;
      case 'es':
        setUserLanguage('es');
        break;
      default:
        return state;
    }
  };

  //Sets the initial language from the browser
  useEffect(() => {
    let returnLang = userLanguage;
    if (
      (returnLang === undefined || returnLang === '') &&
      languageCookie !== 'null' &&
      languageCookie !== null &&
      languageCookie !== '' &&
      languageCookie !== undefined
    ) {
      returnLang = languageCookie;
      setUserLanguage(languageCookie);
      return;
    }

    if ((userLanguage === undefined || userLanguage === '') && languageCookie === 'null') {
      let userLang = navigator.language || navigator.userLanguage;
      userLang = userLang.substring(0, 2);
      if (userLang !== 'en' && userLang !== 'es') {
        userLang = 'en';
      }
      setUserLanguage(userLang);
      returnLang = userLang;
    }
    setDictionary(updateHardCodedText(returnLang));
  }, [userLanguage]);

  //Update the hard coded text with the translated text
  function updateHardCodedText(overRideLanguage) {
    if ((userLanguage !== undefined && userLanguage !== '') || overRideLanguage !== undefined) {
      const lang = overRideLanguage !== undefined ? overRideLanguage : userLanguage;
      return FetchTranslationsByLanguage(lang);
    }
  }

  //dictionary hook
  useEffect(() => {
    setVersionCounter(versionCounter + 1);
  }, [dictionary]);

  const [userDictionary, dispatch] = useReducer(reducer, dictionary);

  //Validate the registration number
  const validateRegNum = (regNum) => {
    setIsLoading(true);
    fetch(apiAdress + '/evac_hotel_api.php?validate=FEMAEvacAPI' + '&reg_number=' + regNum, {
      method: 'GET',
      headers: { 'Content-Type': '*' },
    })
      .then((res) => {
        const tempRes = res.json();
        return tempRes;
      })
      .then((data) => {
        if (data.Status.toLowerCase() === 'success') {
          //|| regNum === '000111' || regNum === '000222'
          //update url to /results
          setRegNum(regNum);
          setRadiusState(25);
          setSearchState('');
          setErrorOverlayOpen(false);
          setIsLoading(false);
          setIsGeo(data.isGeo);

          //Get returnURL from cookie
          if (returnURL !== undefined && returnURL !== null && returnURL !== '') {
            window.location.href = returnURL;
            //delete returnURL cookie
            document.cookie = 'returnURL=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          } else {
            window.location.href = '/#/find';
          }
        } else {
          const error = data['Error Message'];
          console.log('Error: ', error);
          setErrorMessage(error);
          setErrorOverlayOpen(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log('Error -', err);
        setIsLoading(false);
        setError({
          type: 'unexpected',
          show: true,
        });
      });
  };
  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  return (
    <ErrorProvider>
      <RefreshTimerProvider>
        <translationContext.Provider
          value={{
            translations: dictionary,
            language: userLanguage,
            setUserLanguage: setUserLanguage,
            dictionaryDispatch: dispatch,
            versionCounter: versionCounter,
            errorMessage: errorMessage,
            errorOverlayOpen: errorOverlayOpen,
            setErrorOverlayOpen: setErrorOverlayOpen,
          }}
        >
          <urlParamsContext.Provider
            value={{
              regNum: regNum,
              setRegNum: setRegNum,
              radius: radiusState,
              setRadius: setRadiusState,
              search: searchState,
              setSearch: setSearchState,
              validateRegNum: validateRegNum,
              latitude: latitude,
              setLatitude: setLatitude,
              longitude: longitude,
              setLongitude: setLongitude,
              selected: selected,
              setSelected: setSelected,
              isGeo: isGeo,
              setIsGeo: setIsGeo,
              isAdaAccomodations: isAdaAccomodations,
              setIsAdaAccomodations: setIsAdaAccomodations,
              isMealsProvided: isMealsProvided,
              setIsMealsProvided: setIsMealsProvided,
              isPetsAllowed: isPetsAllowed,
              setIsPetsAllowed: setIsPetsAllowed,
              selectedFilterHotelNames: selectedFilterHotelNames,
              setSelectedFilterHotelNames: setSelectedFilterHotelNames,
              filterOptions: filterOptions,
              setFilterOptions: setFilterOptions,
              filtersInUse: filtersInUse,
              setFiltersInUse: setFiltersInUse,
              locationSelected,
              setLocationSelected,
              showError,
              setShowError,
              error, //Used for the DynamicErrorOverlay on Registration Page currently
              setError, //Used for the DynamicErrorOverlay on Registration Page currently
              completeUrlString:completeUrlString
            }}
          >
            <HotelProvider parameters={parameters}>
              <Router>
                <Routes>
                  <Route path="/" element={<Registration />} />
                  <Route path="/find" element={<Find />} />
                  <Route path="/results" element={<Results />} />
                  <Route path="/help" element={<Help />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Router>
            </HotelProvider>
          </urlParamsContext.Provider>
        </translationContext.Provider>
      </RefreshTimerProvider>
    </ErrorProvider>
  );
}

export default App;
