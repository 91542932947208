import React from 'react';
import './NotFound.css';
import Navigation from '../../components/Navigation/Navigation';

document.title = 'Page Not Found | FEMA TSA Lodging Providers';

function NotFound() {
  return (
    <div className="notfound-page-wrap">
      <Navigation />
      <div className="notfound-page-center">
        <h1 className="notfound-headline">Page Not Found</h1>
        <p className="notfound-text">
          <a href="/">Return to Home page</a>
        </p>
      </div>
    </div>
  );
}

export default NotFound;
