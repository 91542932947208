import React, { useState, useRef, useEffect } from 'react';
import './ResultsOverlay.css';
import GallerySlider from '../GallerySlider/GallerySlider';
import DetailsInfo from './DetailsInfo';
import { translationContext } from '../../App';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { ViewedHotelsContext } from '../../contexts/ViewedHotelsProvider';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import Results from '../../pages/Find/Find';
import BedIcon  from '../../assets/bed.png'
function Accordion(props) {
  const [isOpen, setIsOpen] = useState(false);
  const handleVisibilityToggle = () => setIsOpen(!isOpen);
  return (
    <section className="accordion-section" data-expanded={isOpen}>
      <h2 className="accordion-heading">
        <button
          aria-controls={props.FAQID}
          aria-expanded={isOpen}
          className="accordion-button"
          onClick={handleVisibilityToggle}
        >
          {props.Question}
        </button>
      </h2>
      <div
        className="accordion-content"
        id={props.FAQID}
        hidden={!isOpen}
        dangerouslySetInnerHTML={{ __html: props.Answer }}
      ></div>
    </section>
  );
}

function ResultsOverlay({ selectedHotel, onClose, show, index, lastFocusedElement, trackCallClick}) {
  // const closeButtonRef = useRef(null);
  // const previouslyFocusedElement = useRef(null);
  const translations = React.useContext(translationContext);
  const dictionary = translations.translations;
  const KitchenInRoom = dictionary.find((translation) => translation.code === 'Kitchen-In-Room').text;
  const NumRoomsWithADA = dictionary.find((translation) => translation.code === 'Num-of-Rooms-With-ADA').text;
  const Unknown = dictionary.find((translation) => translation.code === 'Unknown').text;
  const ToBookPleaseCall = dictionary.find((translation) => translation.code === 'To-Book-Please-Call').text;
  const Distance = dictionary.find((translation) => translation.code === 'Distance').text;
  const StepsTitle1 = dictionary.find((translation) => translation.code === 'Steps-Title-1').text;
  const StepsTitle2 = dictionary.find((translation) => translation.code === 'Steps-Title-2').text;
  const StepsTitle3 = dictionary.find((translation) => translation.code === 'Steps-Title-3').text;
  const StepsDesc1 = dictionary.find((translation) => translation.code === 'Steps-Desc-1').text;
  const StepsDesc2 = dictionary.find((translation) => translation.code === 'Steps-Desc-2').text;
  const StepsDesc3 = dictionary.find((translation) => translation.code === 'Steps-Desc-3').text;
  const Called = dictionary.find((translation) => translation.code === 'Called').text;
  const Viewed = dictionary.find((translation) => translation.code === 'Viewed').text;

  const NeedHelp = dictionary.find((translation) => translation.code === 'Need-Help').text;
  const NextSteps = dictionary.find((translation) => translation.code === 'Next-Steps').text;
  const FAQ001 = dictionary.find((translation) => translation.code === 'FAQ001').text;
  const FAQ001HTML = dictionary.find((translation) => translation.code === 'FAQ001HTML').text;
  const FAQ002 = dictionary.find((translation) => translation.code === 'FAQ002').text;
  const FAQ002HTML = dictionary.find((translation) => translation.code === 'FAQ002HTML').text;
  const FAQ003 = dictionary.find((translation) => translation.code === 'FAQ003').text;
  const FAQ003HTML = dictionary.find((translation) => translation.code === 'FAQ003HTML').text;
  const FAQ004 = dictionary.find((translation) => translation.code === 'FAQ004').text;
  const FAQ004HTML = dictionary.find((translation) => translation.code === 'FAQ004HTML').text;
  const FAQ005 = dictionary.find((translation) => translation.code === 'FAQ005').text;
  const FAQ005HTML = dictionary.find((translation) => translation.code === 'FAQ005HTML').text;
  const { viewedHotels } = React.useContext(ViewedHotelsContext);
  const { calledHotels, addCalledHotel } = React.useContext(ViewedHotelsContext);
  const viewed = viewedHotels.includes(index);
  const overlayRootRef = useRef(null);
  const closeButtonRef = useRef(null);

  const handleClose = () => {
    onClose();
    lastFocusedElement.focus();
  };

  const handlePhoneNumberClick = (e) => {
    addCalledHotel(index);
    trackCallClick && trackCallClick();
  };
  const handleCloseOnEnter = (event) => {
    // Check if 'Enter' was pressed
    if (event.key === 'Enter') {
      handleClose();
    }
  };

  useEffect(() => {
    if (!show) {
      return; // Early return if `show` is `false`
    }

    const overlayRoot = overlayRootRef.current;
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const focusableContent = Array.from(overlayRoot.querySelectorAll(focusableElements));
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    const trapFocus = (event) => {
      if (document.activeElement === firstFocusableElement && event.shiftKey && event.key === 'Tab') {
        event.preventDefault();
        lastFocusableElement.focus();
      } else if (document.activeElement === lastFocusableElement && !event.shiftKey && event.key === 'Tab') {
        event.preventDefault();
        firstFocusableElement.focus();
      }
    };

    overlayRoot.addEventListener('keydown', trapFocus);
    closeButtonRef.current.focus();

    return () => overlayRoot.removeEventListener('keydown', trapFocus);
  }, [show]);

  if (!show) {
    return null;
  }

  const ResultsTitle = ({ name }) => {
    // Split the name based on the '(' character
    const [hotelName, hotelNumberWithBracket] = name.split('(');

    // Remove the closing bracket ')' from the hotelNumber
    const hotelNumber = hotelNumberWithBracket.replace(')', '');

    return (
      <p className="results-overlay-title">
        <span className="name">{hotelName.trim()} </span>
        <span className="number">({hotelNumber})</span>
      </p>
    );
  };

  const renderImages = (selectedHotel) => {
    if (selectedHotel.Images && selectedHotel.Images.ImageURL.length > 0) {
      const imagesToRender = selectedHotel.Images.ImageURL.map((url) => ({
        url: !url.endsWith("no_hotel_results.jpg") ? url : BedIcon,
        isValid: !url.endsWith("no_hotel_results.jpg"),
      }));
      return <GallerySlider hotelName={selectedHotel.Name} images={imagesToRender} />;
    } else {
      // If ImageURL object is not present or has no data, return a default icon
      return (
        <div className="map-info-image-wrap bed">
          <img src={BedIcon} alt={'Photo of Default Hotel'} width="300" height="156" loading="lazy" />
        </div>
      );
    }
  };

  return (
    <div ref={overlayRootRef} className="results-overlay-wrap">
      <div className="results-overlay">
        <a
          className="results-overlay-close"
          onClick={handleClose}
          tabIndex="0"
          ref={closeButtonRef}
          onKeyDown={handleCloseOnEnter}
        >
          <span className="material-symbols-rounded">close</span>
        </a>
        <div className="results-overlay-flex">
          <SimpleBar style={{ maxHeight: '100%' }} autoHide={false}>
            <div>
              <div className="results-overlay-slide-wrap">
                {/* {selectedHotel.Images.ImageURL.length > 0 ? (
                  <GallerySlider hotelName={selectedHotel.Name} images={selectedHotel.Images} />
                ) : (
                  <div className="map-info-image-wrap">
                    <img
                      src={BedIcon}
                      alt={'Photo of '}
                      width="300"
                      height="156"
                      loading="lazy"
                    />
                  </div>
                )} */}
                  {renderImages(selectedHotel)}

                {/* TODO: Wire up if location has been viewed and called. Only show 1 flag max. */}
                {calledHotels.includes(index) && <div className="results-overlay-slide-flag loud">{Called}</div>}
                {viewed && <div className="results-overlay-slide-flag">{Viewed}</div>}
              </div>
              <div className="results-overlay-content">
                <ResultsTitle name={selectedHotel.Name} />

                <p className="results-overlay-distance">
                  {Distance} <br />
                  {selectedHotel.Distance} {'mi'}
                </p>

                <p className={'results-overlay-address no-margin-bottom'}>{selectedHotel.Address_1}</p>

                {selectedHotel.Address_2 !== '' && (
                  <p className="results-overlay-address no-margin-bottom ">{selectedHotel.Address_2}</p>
                )}
                <p className="results-overlay-address">
                  {`${selectedHotel.City}, ${selectedHotel.State} ${selectedHotel.Zip}`}
                </p>

                {/* This is for phase2 */}
                <p className="results-overlay-summary" style={{ display: 'none' }}>
                  {KitchenInRoom}: <b>{Unknown}</b> <br />
                  {NumRoomsWithADA}: <b>{Unknown}</b>
                </p>
                <div className="results-overlay-feature-list">
                  <DetailsInfo
                    pets={selectedHotel.Amenities.Pets}
                    pets_comments={selectedHotel.Amenities.Pets_Comments}
                    ada={selectedHotel.Amenities.ADA}
                    ada_comments={selectedHotel.Amenities.ADA_Comments}
                    kitchen={selectedHotel.Amenities.Kitchen}
                    kitchen_comments={selectedHotel.Amenities.Kitchen_Comments}
                  />
                </div>

                <div className="results-overlay-step-wrap">
                  <p className="results-overlay-faq-heading">{NextSteps}</p>
                  <div className="results-overlay-step">
                    <span className="results-overlay-step-number">
                      <span className="material-symbols-rounded">check</span>
                    </span>
                    <span className="results-overlay-step-title">{StepsTitle1}</span>
                    <span className="results-overlay-step-text">{StepsDesc1}</span>
                  </div>
                  <div className="results-overlay-step">
                    <span className="results-overlay-step-number">2</span>
                    <span className="results-overlay-step-title">{StepsTitle2}</span>
                    <span className="results-overlay-step-text">{StepsDesc2}</span>
                  </div>
                  <div className="results-overlay-step">
                    <span className="results-overlay-step-number">3</span>
                    <span className="results-overlay-step-title">{StepsTitle3}</span>
                    <span className="results-overlay-step-text">{StepsDesc3}</span>
                  </div>
                </div>
                <div className="results-overlay-faq-wrap">
                  <p className="results-overlay-faq-heading">{NeedHelp}</p>
                  <Accordion Question={FAQ001} Answer={FAQ001HTML} FAQID="faq001" />
                  <Accordion Question={FAQ002} Answer={FAQ002HTML} FAQID="faq002" />
                  <Accordion Question={FAQ003} Answer={FAQ003HTML} FAQID="faq003" />
                  <Accordion Question={FAQ004} Answer={FAQ004HTML} FAQID="faq004" />
                  <Accordion Question={FAQ005} Answer={FAQ005HTML} FAQID="faq005" />
                </div>
              </div>
            </div>
          </SimpleBar>
        </div>
        <a
          className="results-overlay-booking-snackbar"
          href={'tel:+1-' + selectedHotel.Phone}
          onClick={handlePhoneNumberClick}
        >
          <span className="results-overlay-booking-instructions">{ToBookPleaseCall}</span>
          <span className="results-overlay-booking-phone">{formatPhoneNumber(selectedHotel.Phone)}</span>
        </a>
      </div>
    </div>
  );
}

export default ResultsOverlay;
