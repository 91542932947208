import React, { useEffect, useRef, useState, useContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import defaultPin from '../../assets/pin.png';
import pinActive from '../../assets/pin-active.png';
import group from '../../assets/group.png';
import groupActive from '../../assets/group-active.png';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import DefaultRenderer from './DefaultRenderer';
import GallerySlider from '../GallerySlider/GallerySlider';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { ViewedHotelsContext } from '../../contexts/ViewedHotelsProvider';
import { trackMapInteraction, trackCallClick } from '../../utils/analyticEvents';
import { translationContext } from '../../App';

function InfoWindowContent({ hotel, onHotelClick }) {
  const name = hotel.Name.split('(')[0].trim();
  const imageUrls = hotel.Images?.ImageURL || [];
  const firstImageUrl = imageUrls[0];

  return (
    <div id={`infowindow-${hotel.VendorID}`} className="infowindow-content">
      <div onClick={() => onHotelClick(hotel, hotel.VendorID)}>
        {/* <GallerySlider hotelName={name} images={hotel.Images} /> */}
        <div className="map-info-image-wrap">
          <img src={firstImageUrl || hotel.image} alt={'Photo of ' + name} width="300" height="156" loading="lazy" />
        </div>
        <div className="map-info-content">
          <h2>{name}</h2>
          <p>{hotel.Address_1}</p>
          <p>
            {hotel.City}, {hotel.State} {hotel.Zip}
          </p>
          <p>
            <a href={'tel:+1-' + formatPhoneNumber(hotel.Phone)}>{formatPhoneNumber(hotel.Phone)}</a>
          </p>
          <div className="map-info-button">View Details</div>
        </div>
      </div>
    </div>
  );
}

const mapStyles = [
  { featureType: 'landscape.man_made', elementType: 'geometry', stylers: [{ color: '#f7f1df' }] },
  { featureType: 'landscape.natural', elementType: 'geometry', stylers: [{ color: '#d0e3b4' }] },
  { featureType: 'landscape.natural.terrain', elementType: 'geometry', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.business', elementType: 'all', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.medical', elementType: 'geometry', stylers: [{ color: '#fbd3da' }] },
  { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#bde6ab' }] },
  { featureType: 'road', elementType: 'geometry.stroke', stylers: [{ visibility: 'off' }] },
  { featureType: 'road', elementType: 'labels', stylers: [{ visibility: 'on' }] },
  { featureType: 'road.highway', elementType: 'geometry.fill', stylers: [{ color: '#ffe15f' }] },
  { featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{ color: '#efd151' }] },
  { featureType: 'road.arterial', elementType: 'geometry.fill', stylers: [{ color: '#ffffff' }] },
  { featureType: 'road.local', elementType: 'geometry.fill', stylers: [{ color: 'black' }] },
  { featureType: 'transit.station.airport', elementType: 'geometry.fill', stylers: [{ color: '#cfb2db' }] },
  { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#a2daf2' }] },
];

function MapContainer({ data, hoveredHotel, handleHotelSelection }) {
  const googleMapRef = useRef();
  const [currentInfoWindow, setCurrentInfoWindow] = useState(null);
  const markersWithInfoWindows = useRef([]);
  const currentInfoWindowRef = useRef();
  const markerClusterRef = useRef(null);
  const myRenderer = new DefaultRenderer();
  const defaultRendererRef = useRef(new DefaultRenderer(false));
  const activeRendererRef = useRef(new DefaultRenderer(true));
  const { addViewedHotel, addCalledHotel } = React.useContext(ViewedHotelsContext);
  const localTranslationContext = useContext(translationContext);

  const defaultIcon = {
    url: defaultPin,
    scaledSize: new window.google.maps.Size(50, 50),
  };

  const activeIcon = {
    url: pinActive,
    scaledSize: new window.google.maps.Size(50, 50),
  };
  // Define cluster icons
  const defaultClusterIcon = {
    url: group,
    scaledSize: new window.google.maps.Size(50, 50), // size
  };

  const activeClusterIcon = {
    url: groupActive,
    scaledSize: new window.google.maps.Size(50, 50), // size
  };

  useEffect(() => {
    createGoogleMap();
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      const infowindowContent = event.target.closest('.infowindow-content');
      if (infowindowContent) {
        // Extract VendorID from the id of the closest .infowindow-content element
        const VendorID = parseInt(infowindowContent.id.split('-')[1]);
        // Find the hotel with this VendorID
        const hotel = data.find((h) => h.VendorID === VendorID);
        // If hotel was found, call handleHotelSelection
        if (hotel) {
          const imageUrls = hotel.Images?.ImageURL || [];
          const hasImages = imageUrls.length > 0;
          if (event.target.tagName === 'A' && event.target.href.startsWith('tel:')) {
            addCalledHotel(hotel.VendorID);
            trackCallClick(
              localTranslationContext.language,
              hotel.VendorID,
              hotel.Name,
              hasImages,
              'n/a',
              hotel.Distance,
              'map'
            );
          } else {
            addViewedHotel(hotel.VendorID);
            handleHotelSelection(hotel, VendorID, 'n/a', 'map');
            sessionStorage.setItem('viewSource', 'map');
          }
        }
      }
    };

    document.addEventListener('click', handleClick);

    // Cleanup function
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [data, handleHotelSelection]);
  const prevHoveredHotelRef = useRef();

  useEffect(() => {
    if (prevHoveredHotelRef.current) {
      const prevMarkerWithInfoWindow = markersWithInfoWindows.current.find(
        (item) => item.marker.VendorID === prevHoveredHotelRef.current.VendorID
      );

      if (prevMarkerWithInfoWindow) {
        if (isMarkerInCluster(prevMarkerWithInfoWindow.marker)) {
          const cluster = getClusterForMarker(prevMarkerWithInfoWindow.marker);
          cluster.marker.setIcon(defaultClusterIcon); // reset to default cluster icon
          cluster.marker.label.color = 'rgba(0, 82, 136, 1)';
        }
      }
    }

    prevHoveredHotelRef.current = hoveredHotel; // update the reference for next render

    // Loop over all markers and reset their icon and opacity
    markersWithInfoWindows.current.forEach((item) => {
      item.marker.setIcon(defaultIcon);
      item.marker.setOpacity(1);
    });

    if (hoveredHotel) {
      const markerWithInfoWindow = markersWithInfoWindows.current.find(
        (item) => item.marker.VendorID === hoveredHotel.VendorID
      );

      if (markerWithInfoWindow) {
        if (currentInfoWindowRef.current) {
          currentInfoWindowRef.current.close();
        }

        // Change the marker icon to the active group icon
        markerWithInfoWindow.marker.setIcon(activeIcon);
        markerWithInfoWindow.marker.setOpacity(1);
        if (isMarkerInCluster(markerWithInfoWindow.marker)) {
          const cluster = getClusterForMarker(markerWithInfoWindow.marker);
          cluster.marker.setIcon(activeClusterIcon);
          cluster.marker.label.color = 'rgba(255, 255, 255, 1)';
        }
        markersWithInfoWindows.current.forEach((item) => {
          if (item.marker.VendorID !== hoveredHotel.VendorID) {
            item.marker.setIcon(defaultIcon);
            item.marker.setOpacity(0.7);
          }
        });

        currentInfoWindowRef.current = markerWithInfoWindow.infowindow;
      }
    }
  }, [hoveredHotel]);

  function isMarkerInCluster(marker) {
    const clusters = markerClusterRef.current?.clusters;
    for (let i = 0; i < clusters.length; i++) {
      const markersInCluster = clusters[i].markers;
      // Check if the markers array length is greater than 1 (meaning it's a cluster)
      if (markersInCluster.length > 1 && markersInCluster.includes(marker)) {
        return true;
      }
    }
    return false;
  }

  function getClusterForMarker(marker) {
    const clusters = markerClusterRef.current?.clusters;
    for (let i = 0; i < clusters.length; i++) {
      const markersInCluster = clusters[i].markers;
      // Check if the markers array length is greater than 1 (meaning it's a cluster)
      if (markersInCluster.length > 1 && markersInCluster.includes(marker)) {
        return clusters[i]; // Return the cluster
      }
    }
    return null; // Return null if the marker is not part of a cluster
  }

  const createGoogleMap = () => {
    let center = { lat: 37.7749, lng: -122.4194 }; // Default center (San Francisco)
    markersWithInfoWindows.current = [];

    if (data.length > 0) {
      center = {
        lat: parseFloat(data[0].Latitude),
        lng: parseFloat(data[0].Longitude),
      };
    }
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

    const map = new window.google.maps.Map(googleMapRef.current, {
      zoom: 12,
      center: center,
      styles: mapStyles,
      mapTypeControl: !isMobile,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      maxZoom: 18,
      fullscreenControl: !isMobile,
    });

    map.addListener('click', () => {
      trackMapInteraction('Map Clicked');
    });

    map.addListener('dragend', () => {
      trackMapInteraction('Map Panned');
    });

    map.addListener('maptypeid_changed', () => {
      const mapType = map.getMapTypeId();
      if (mapType === 'satellite') {
        trackMapInteraction('Satellite View Enabled');
      } else if (mapType === 'hybrid') {
        trackMapInteraction('Hybrid View Enabled');
      } else if (mapType === 'terrain') {
        trackMapInteraction('Terrain View Enabled');
      } else {
        trackMapInteraction('Roadmap View Enabled');
      }
    });
    const streetView = map.getStreetView();
    google.maps.event.addListener(streetView, 'visible_changed', () => {
      const mode = streetView.getVisible();
      if (mode) {
        trackMapInteraction('Street View Entered');
      } else {
        trackMapInteraction('Street View Exited');
      }
    });

    const markerToData = new Map();
    const markers = data.map((hotel) => {
      const position = new window.google.maps.LatLng(parseFloat(hotel.Latitude), parseFloat(hotel.Longitude));

      const marker = new window.google.maps.Marker({
        position: position,
        title: hotel.Name,
        icon: defaultIcon,
        opacity: 1,
        VendorID: hotel.VendorID,
      });

      markerToData.set(marker, hotel); // Associate the hotel data with the marker

      const infowindow = new window.google.maps.InfoWindow();

      marker.addListener('click', function () {
        if (currentInfoWindowRef.current) {
          currentInfoWindowRef.current.close();
        }

        infowindow.setContent(
          ReactDOMServer.renderToString(<InfoWindowContent hotel={hotel} onHotelClick={handleHotelSelection} />)
        );
        infowindow.open(map, marker);
        currentInfoWindowRef.current = infowindow;

        markersWithInfoWindows.current.forEach((item) => {
          const associatedData = markerToData.get(item.marker);
          if (associatedData.VendorID !== hotel.VendorID) {
            item.marker.setIcon(defaultIcon);
            item.marker.setOpacity(0.7);
          } else {
            item.marker.setIcon(activeIcon);
            item.marker.setOpacity(1);
          }
        });
      });
      infowindow.addListener('closeclick', function () {
        markersWithInfoWindows.current.forEach((item) => {
          item.marker.setIcon(defaultIcon);
          item.marker.setOpacity(1); // Reset opacity to default
        });
      });

      markersWithInfoWindows.current.push({
        marker: marker,
        infowindow: infowindow,
      });
      marker.setMap(map);

      return marker;
    });

    markers.forEach((marker) => {
      marker.addListener('click', () => {
        trackMapInteraction('Hotel Marker Clicked', marker.title); // The title of the marker (hotel name) is passed for more details.
      });
    });

    const markerCluster = new MarkerClusterer({
      map,
      markers,
      renderer: defaultRendererRef.current,
    });

    markerClusterRef.current = markerCluster;

    // Calculate the bounds based on individual markers
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((marker) => {
      bounds.extend(marker.getPosition());
    });

    // Fit the map to the bounds of the markers
    map.fitBounds(bounds);
    //check if all google maps has loaded before adding zoom
    const tilesLoadedListener = google.maps.event.addListener(map, 'tilesloaded', () => {
      map.addListener('zoom_changed', () => {
        trackMapInteraction('Map Zoomed');
      });
      google.maps.event.removeListener(tilesLoadedListener);
  });
  };


  return <div id="google-map" ref={googleMapRef} />;
}

export default React.memo(MapContainer);
