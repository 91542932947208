import React, { useContext } from 'react';
import './ErrorOverlay.css';
import { translationContext } from '../../App';
import { useError } from '../../contexts/ErrorContext';
import { HotelContext } from '../../contexts/HotelProvider';
import { urlParamsContext } from '../../App';

export const NoResults = ({ isGeo, setIsLoading, isFilter, nextRadius, resultHotelCount }) => {
  const { fetchHotelsWithoutLoading } = useContext(HotelContext);
  const localTranslationContext = React.useContext(translationContext);
  const dictionary = localTranslationContext.translations;
  const urlParams = React.useContext(urlParamsContext);
  const { setError: setStateError } = useError();
  const radiusIncrements = [25, 50, 100, 250, 500, 1000, 3000, 10000];

  const NoResultsReturned = dictionary.find((translation) => translation.code === 'No-Results-Returned').text;
  const ExpandSearchRadius = dictionary.find((translation) => translation.code === 'Expand-Search-Radius').text;
  const Unlimited = dictionary.find((translation) => translation.code === 'Unlimited').text;
  const Miles = dictionary.find((translation) => translation.code === 'Miles').text.toLowerCase();
  const ExpandSearchRadiusIsGeo = dictionary.find(
    (translation) => translation.code === 'Expand-Search-Radius-IsGeo'
  ).text;

  const [tempNextRadius, setTempNextRadius] = React.useState(!isGeo && nextRadius === '10000' ? Unlimited : nextRadius);

  const expandRadius = async (initialRadius = urlParams.radius) => {
    setIsLoading(true);
    let currentIndex = radiusIncrements.indexOf(Number(initialRadius));
    let apiNextRadius;
    if (isGeo) {
      apiNextRadius = 10000; // Geofenced case: set radius to 10,000 miles
      urlParams.setRadius(apiNextRadius);
    } else {
      apiNextRadius = radiusIncrements[currentIndex + 1];
      if (apiNextRadius === undefined) {
        apiNextRadius = 10000;
        // check if the radius exceeds maximum limit
        setStateError({ type: 'noHotelsMaxRadius', isGeo: '', show: true });
        urlParams.setRadius(apiNextRadius);
        setTempNextRadius(undefined);
        return;
      }
    }
    fetchHotelsWithoutLoading(urlParams.latitude, urlParams.longitude, apiNextRadius, urlParams.regNum).then((data) => {
      if (data?.allHotels?.length >= 2500 || data?.filteredHotels?.length > 0) {
        urlParams.setRadius(apiNextRadius);
        setIsLoading(false);
      } else if (data?.filteredHotels?.length === 0 && isGeo) {
        setStateError({ type: 'noHotelsMaxRadius', isGeo: '', show: true });
        setIsLoading(false);
      } else {
        expandRadius(apiNextRadius);
      }
    });
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      expandRadius();
    }
  };

  return (
    <div className="results-list-error-wrap">
      <div className="results-list-error-inner" dangerouslySetInnerHTML={{ __html: NoResultsReturned }} />
      {tempNextRadius &&
        tempNextRadius !== undefined &&
        tempNextRadius !== Unlimited &&
        tempNextRadius !== 10000 &&
        resultHotelCount <= 2500 && (
          <div className="error-overlay-button-wrap">
            <a className="error-overlay-button" onClick={() => expandRadius()} onKeyDown={handleEnter} tabIndex="0">
              {isGeo
                ? ExpandSearchRadiusIsGeo
                : ExpandSearchRadius.replace('{radius}', tempNextRadius).replace('{miles}', Miles)}
            </a>
          </div>
        )}
    </div>
  );
};
