let address = '';
if (window.location.origin.includes('femaemergencyhotels.com')) {
  address = window.location.origin;
} else if (NODE_ENVIRONMNET.WEBPACK_SERVE) {
  address = '/api';
} else if (window.location.origin.includes('azurewebsites.net')) {
  address = '/php';
} else {
  address = '';
}

export default address;
