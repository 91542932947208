import React, { useEffect, useState, useContext } from 'react';
import './Registration.css';
import Logo from '../../assets/FEMA-logo-blue.png';
import { DynamicErrorOverlay } from '../../components/ErrorOverlay/DynamicErrorOverlay';
import ForgotOverlay from '../../components/ErrorOverlay/ForgotOverlay';
import CookieOverlay from '../../components/ErrorOverlay/CookieOverlay';
import { translationContext } from '../../App';
import { urlParamsContext } from '../../App';
import { trackAlternatePaths, trackLanguageChange } from '../../utils/analyticEvents';
import Loading from '../../components/Loading/Loading';
/*Going with another comment*/
function Registration() {
  const localTranslationContext = useContext(translationContext);
  const urlParams = useContext(urlParamsContext);

  const [forgotOverlayOpen, setForgotOverlayOpen] = useState(false);
  const [cookieOverlayOpen, setCookieOverlayOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let dictionary = localTranslationContext.translations;
  let language = localTranslationContext.language;
  let TSA = dictionary.find((translation) => translation.code === 'TSA').text;
  let EnterRegNumber = dictionary.find((translation) => translation.code === 'Enter-RegNumber').text;
  let Submit = dictionary.find((translation) => translation.code === 'Submit').text;
  let ForgotRegNumber = dictionary.find((translation) => translation.code === 'Forgot-RegNumber').text;
  let Eligible1 = dictionary.find((translation) => translation.code === 'Eligible-1').text;
  let Eligible2 = dictionary.find((translation) => translation.code === 'Eligible-2').text;
  let StepsTitle1 = dictionary.find((translation) => translation.code === 'Steps-Title-1').text;
  let StepsTitle2 = dictionary.find((translation) => translation.code === 'Steps-Title-2').text;
  let StepsTitle3 = dictionary.find((translation) => translation.code === 'Steps-Title-3').text;
  let StepsDesc1 = dictionary.find((translation) => translation.code === 'Steps-Desc-1').text;
  let StepsDesc2 = dictionary.find((translation) => translation.code === 'Steps-Desc-2').text;
  let StepsDesc3 = dictionary.find((translation) => translation.code === 'Steps-Desc-3').text;
  let CookiePolicy = dictionary.find((translation) => translation.code === 'Cookie-Policy').text;
  let NeedHelpLink = dictionary.find((translation) => translation.code === 'Need-Help-Link').text;
  let Language = dictionary.find((translation) => translation.code === 'Language').text;

  document.title = TSA;
  useEffect(() => {
    if (localTranslationContext.errorOverlayOpen) {
      urlParams.setError({
        type: 'notValidReg_notTSA',
        isGeo: 'Y',
        show: true,
      });
    }
  }, [localTranslationContext.errorOverlayOpen, urlParams.setError]);

  return (
    <main className="registration-page">
      <div className="registration-page-background">&nbsp;</div>
      <div className="registration-top-translate-bar">
        <div className="registration-translate-logo">
          <img src={Logo} alt="FEMA logo" />
        </div>
        <div className="registration-translate-text" data-lang={language}>
          {TSA}
        </div>
        <fieldset className="registration-translate-radio-wrap">
          <legend className="registration-translate-radio-legend">{Language}</legend>
          <label className="registration-translate-radio-label">
            <input
              key="registration-translate-en"
              className="registration-translate-radio-input"
              type="radio"
              name="language"
              value="english"
              onClick={() => {
                localTranslationContext.setUserLanguage('en');
                trackLanguageChange('Language Change', 'en');
              }}
              checked={language === 'en'}
            />
            <span className="registration-translate-radio-text">English</span>
          </label>
          <label className="registration-translate-radio-label">
            <input
              key="registration-translate-es"
              className="registration-translate-radio-input"
              type="radio"
              name="language"
              value="español"
              onClick={() => {
                localTranslationContext.setUserLanguage('es');
                trackLanguageChange('Language Change', 'es');
              }}
              checked={language === 'es'}
            />
            <span className="registration-translate-radio-text">Español</span>
          </label>
        </fieldset>
      </div>
      <Loading isLoading={isLoading} />
      <form
        className="registration-form"
        onSubmit={(e) => {
          urlParams.validateRegNum(urlParams.regNum);
          e.preventDefault();
        }}
      >
        <h1 className="registration-h1">{EnterRegNumber}</h1>
        <div className="registration-input-wrap">
          <input
            className="registration-input"
            type="number"
            pattern="\d*"
            min="0"
            max="999999999"
            placeholder="ex: 123456789"
            value={urlParams.regNum}
            onChange={(e) => urlParams.setRegNum(e.target.value)}
            aria-label="Enter Registration Number"
          />
        </div>
        <button className="registration-submit" type="submit">
          {Submit}
        </button>
        <button
          type="button"
          className="registration-forgot wide"
          onClick={() => {
            setForgotOverlayOpen(true);
            trackAlternatePaths('Forgot Registration Clicked');
          }}
        >
          {ForgotRegNumber}
        </button>
      </form>
      <div className="registration-step-wrap">
        <div className="registration-step">
          <div className="registration-step-number">1</div>
          <div className="registration-step-title">{StepsTitle1}</div>
          <div className="registration-step-text">{StepsDesc1}</div>
        </div>
        <div className="registration-step">
          <div className="registration-step-number">2</div>
          <div className="registration-step-title">{StepsTitle2}</div>
          <div className="registration-step-text">{StepsDesc2}</div>
        </div>
        <div className="registration-step">
          <div className="registration-step-number">3</div>
          <div className="registration-step-title">{StepsTitle3}</div>
          <div className="registration-step-text">{StepsDesc3}</div>
        </div>
      </div>
      <div className="registration-help-block">
        <a className="registration-help-link" href="/#/help">
          {NeedHelpLink}
        </a>
      </div>
      <div className="registration-legal-wrap">
        <p className="registration-legal-text">{Eligible1}</p>
        <p className="registration-legal-text">{Eligible2}</p>
        <div className="registration-legal-block">
          <button type="button" className="registration-forgot" onClick={() => setCookieOverlayOpen(true)}>
            {CookiePolicy}
          </button>
        </div>
      </div>
      {urlParams.error.show && <DynamicErrorOverlay error={urlParams.error} setIsLoading={urlParams.setError} />}
      {forgotOverlayOpen && <ForgotOverlay setForgotOverlayOpen={setForgotOverlayOpen} />}
      {cookieOverlayOpen && <CookieOverlay setCookieOverlayOpen={setCookieOverlayOpen} />}
    </main>
  );
}

export default Registration;
