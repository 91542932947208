import React, { useEffect, useState, useContext } from 'react';
import './Results.css';
import Navigation from '../../components/Navigation/Navigation';
import ResultsListItem from '../../components/ResultsListItem/ResultsListItem';
import BackgroundImage from '../../assets/background.jpg';
import Loading from '../../components/Loading/Loading';
import ResultsOverlay from '../../components/ResultsOverlay/ResultsOverlay';
import LocationSearchInput from '../../components/LocationSearchInput/LocationSearchInput';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import ResizeObserver from 'resize-observer-polyfill';
import { translationContext } from '../../App';
import { FetchTranslationByCodeAndLanguage } from '../../services/translation';
import { urlParamsContext } from '../../App';
import { useLocation, useNavigate } from 'react-router-dom';
import RefreshOverlay from '../../components/ErrorOverlay/RefreshOverlay';
import { ViewedHotelsProvider } from '../../contexts/ViewedHotelsProvider';
import { RefreshTimerContext } from '../../contexts/RefreshTimerContext';
import { useError } from '../../contexts/ErrorContext';
import { DynamicErrorOverlay } from '../../components/ErrorOverlay/DynamicErrorOverlay';
import FiltersOverlay from '../../components/FiltersOverlay/FiltersOverlay';
import MapContainer from '../../components/Map/MapContainer';
import { HotelContext } from '../../contexts/HotelProvider';
import { initialFilterOptions } from '../../utils/initialFilterOptions';
import { NoResults } from '../../components/ErrorOverlay/NoResults';
import { trackHotelView, trackCallClick } from '../../utils/analyticEvents';
window.ResizeObserver = ResizeObserver;

/*Start of Results Function*/
function Results() {
  /*Start of Context*/
  const urlParams = React.useContext(urlParamsContext);
  const localTranslationContext = useContext(translationContext);
  const {
    filterCount,
    setFilterCount,
    setIsLoading,
    fetchHotels,
    isLoading,
    allHotels,
    filteredHotels,
    nextRadius,
    handleExpandingRadius,
  } = useContext(HotelContext);
  /*End of Context*/

  /*Start of Translation Text*/
  const dictionary = localTranslationContext.translations;
  const language = localTranslationContext.language;
  const setLanguage = localTranslationContext.dictionaryDispatch;
  const TSA = dictionary.find((translation) => translation.code === 'TSA').text;
  const Results = dictionary.find((translation) => translation.code === 'Results').text;
  const Result = dictionary.find((translation) => translation.code === 'Result').text;
  const ExpandSearchRadius = dictionary.find((translation) => translation.code === 'Expand-Search-Radius').text;
  const Of = dictionary.find((translation) => translation.code === 'Of').text;
  const Miles = dictionary.find((translation) => translation.code === 'Miles').text;
  const Unlimited = dictionary.find((translation) => translation.code === 'Unlimited').text;
  const Showing = dictionary.find((translation) => translation.code === 'Showing').text;
  const Filters = dictionary.find((translation) => translation.code === 'Filters').text;
  const ClearAll = dictionary.find((translation) => translation.code === 'Clear-All').text;
  const ClearFilters = dictionary.find((translation) => translation.code === 'Clear-Filters').text;
  const MapView = dictionary.find((translation) => translation.code === 'Map-View').text;
  const ListView = dictionary.find((translation) => translation.code === 'List-View').text;
  const Filtered = dictionary.find((translation) => translation.code === 'Filtered').text;
  const ResultTitle = dictionary.find((translation) => translation.code === 'ResultTitle').text;
  /*End of Translation Text*/
  document.title = `${ResultTitle} | ${TSA}`;

  /*Start of useState*/
  const [userLanguage, setUserLanguage] = useState('');
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const [englishLabel, setEnglishLabel] = useState('');
  const [spanishLabel, setSpanishLabel] = useState('');
  const [resultErrorMessage, setResultErrorMessage] = useState();
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [selectedHotelIndex, setSelectedHotelIndex] = useState(null);
  const { error, setError } = useError();
  const location = useLocation();
  const navigate = useNavigate();
  const [hoveredHotel, setHoveredHotel] = useState(null);
  const [filtersOverlayOpen, setFiltersOverlayOpen] = useState(false);
  const { refreshOverlayOpen, setRefreshOverlayOpen } = useContext(RefreshTimerContext);
  const [isMobileMapOpen, setMobileMapOpen] = useState(false);
  const [lastFocusedElement, setLastFocusedElement] = useState(null);
  const [showResultsOverlay, setShowResultsOverlay ] = useState(false);
  const toggleFiltersOverlay = () => {
    setFiltersOverlayOpen(!filtersOverlayOpen);
  };
  /*End of useState*/

  /*Start of Const Functions*/
  const handleHotelSelection = (hotel, vendorID, index, viewSource) => {
    const imageUrls = hotel.Images?.ImageURL || [];
    const hasImages = imageUrls.length > 0;
    setSelectedHotelIndex(vendorID);
    setSelectedHotel(hotel);
    setLastFocusedElement(document.activeElement);
   // navigate(location.pathname, { state: { showOverlay: true } });
   setShowResultsOverlay(true);
    trackHotelView(language, hotel.VendorID, hotel.Name, hasImages, index, hotel.Distance, viewSource);
    sessionStorage.setItem('viewSource', 'list');
  };

  const handleMobileMapToggle = () => {
    setMobileMapOpen(!isMobileMapOpen);
  };

  const handleClearFilter = () => {
    setFilterCount(0);
    urlParams.setIsAdaAccomodations(false);
    urlParams.setIsPetsAllowed(false);
    urlParams.setIsMealsProvided(false);
    urlParams.setSelectedFilterHotelNames([]);
    urlParams.setFilterOptions(initialFilterOptions);
  };

  const handleRadiusChange = (e) => {
    const selectedRadius = e.target.value;
    urlParams.setRadius(selectedRadius);
  };
  /*End of Const Functions*/

  useEffect(() => {
    const regNumCookie = document.cookie.replace(/(?:(?:^|.*;\s*)regNum\s*=\s*([^;]*).*$)|^.*$/, '$1');
    const urlParameters = new URLSearchParams(window.location.hash.split('?')[1]);
    const locationName = urlParameters.get('search');
    if (locationName) {
      // Hide the google search;
      setIsLoading(true);
      // But give up if too slow
      setTimeout(() => setIsLoading(false), 1500);
    }

    if (urlParameters && (urlParams.search === undefined || urlParams.search === '' || urlParams.search === null)) {
      urlParams.setSearch(locationName);
      let radius = Number(urlParameters.get('radius'));
      urlParams.setRadius(radius);
      urlParams.setIsAdaAccomodations(urlParameters.get('ada') === 'true');
      urlParams.setIsPetsAllowed(urlParameters.get('pets') === 'true');
      urlParams.setIsMealsProvided(urlParameters.get('kitchen') === 'true');
      urlParams.setSelectedFilterHotelNames(urlParameters.get('hotelNames')?.split(','));
    }

    //If RegNum is not set, redirect to registration page
    if (
      (regNumCookie === undefined || regNumCookie === '' || regNumCookie === null) &&
      (urlParams.regNum === undefined || urlParams.regNum === '' || urlParams.regNum === null)
    ) {
      window.location.href = '/';
      return;
    }

    const handleWindowResize = () => {
      setWindowSize([window.outerWidth, window.outerHeight]);
      setLanguageLabels(window.outerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    setLanguageLabels(window.outerWidth);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  //Get user language from browser
  useEffect(() => {
    if (userLanguage === '') {
      let userLang = navigator.language || navigator.userLanguage;
      userLang = userLang.substring(0, 2);
      if (userLang !== 'en' && userLang !== 'es') {
        userLang = 'en';
      }
      setUserLanguage(userLang);
      setLanguageLabels(window.outerWidth);
      updateHardCodedText(userLang);
    }
  }, [userLanguage]);

  useEffect(() => {
    if (
      urlParams.latitude !== 0 &&
      urlParams.latitude !== undefined &&
      urlParams.longitude !== 0 &&
      urlParams.longitude !== undefined &&
      (urlParams.regNum !== undefined ||
        urlParams.regNum !== '' ||
        urlParams.regNum !== null ||
        urlParams.regNum !== 'null')
    ) {
      fetchHotels(urlParams.latitude, urlParams.longitude, urlParams.radius, urlParams.regNum);
    }
  }, [urlParams.latitude, urlParams.longitude, urlParams.radius, urlParams.regNum]);

  useEffect(() => {
    const handleBrowserBack = () => {
      navigate(`/find?${urlParams.completeUrlString}`);
    };

    window.addEventListener('popstate', handleBrowserBack);

    return () => {
      window.removeEventListener('popstate', handleBrowserBack);
    };
  }, [navigate, urlParams.completeUrlString]);

  useEffect(() => {
    if (filteredHotels.length === 0) {
      setMobileMapOpen(false);
    }
  }, [filteredHotels]);

  function setLanguageLabels(outerWidth) {
    if (outerWidth < 750) {
      setEnglishLabel('EN');
      setSpanishLabel('ES');
    } else {
      setEnglishLabel('English');
      setSpanishLabel('Español');
    }
  }

  function updateHardCodedText(userLang) {
    setResultErrorMessage(FetchTranslationByCodeAndLanguage('No-hotels-found', userLang));
  }
  return (
    <ViewedHotelsProvider>
      <div className="results-wrap" data-filter={filtersOverlayOpen}>
        <div className="filter-map-block">&nbsp;</div>
        <Loading isLoading={isLoading} />
        <div className="results-background">
          <img className="results-background-image" src={BackgroundImage} alt="hotel room key" />
        </div>
        <Navigation
          userLanguage={language}
          setUserLanguage={setLanguage}
          englishLabel={englishLabel}
          spanishLabel={spanishLabel}
        />
        {filtersOverlayOpen && <FiltersOverlay setFiltersOverlayOpen={setFiltersOverlayOpen} />}
        <div className={isMobileMapOpen ? 'results-list mobile-map-open' : 'results-list'}>
          <h1 className="hidden-heading">{Results}</h1>
          <div className="results-list-search-options">
            <div className="results-list-search-options-right" data-value={urlParams.radius} data-lang={language}>
              <a className="results-list-search-options-mobile-back" href={`/#/find?${urlParams.completeUrlString}`}>
                <div className="material-symbols-rounded">arrow_back</div>
              </a>
              <div className="results-desktop-filter-wrap">
                <button className="results-desktop-filter" onClick={toggleFiltersOverlay}>
                  <span className="material-symbols-rounded" aria-hidden="true">
                    tune
                  </span>
                  <span className="results-desktop-filter-label">{Filters}</span>
                  {filterCount > 0 ? <span className="results-desktop-filter-count">{filterCount} </span> : ''}
                </button>
                {filterCount > 0 ? (
                  <button className="results-desktop-filter-clear" onClick={handleClearFilter}>
                    {ClearAll}
                  </button>
                ) : (
                  ''
                )}
              </div>
              <div className="result-panel-form-search-wrap">
                <LocationSearchInput setLatitude={urlParams.setLatitude} setLongitude={urlParams.setLongitude} />
              </div>
              <div className="results-list-search-options-search-radius">
                <select
                  className="results-list-search-select"
                  aria-label="Show Hotels Within"
                  value={urlParams.radius}
                  onChange={handleRadiusChange}
                >
                  <option value="25">25 mi</option>
                  <option value="50">50 mi</option>
                  <option value="100">100 mi</option>
                  {urlParams.radius > 100 ? (
                    <option
                      style={{
                        display: 'none',
                        WebkitAppearance: 'none', // For Safari and Chrome
                        MozAppearance: 'none',
                      }}
                      value={urlParams.radius}
                    >
                      {urlParams.radius === 10000 ? Unlimited : urlParams.radius + ' mi'}
                    </option>
                  ) : (
                    ''
                  )}
                </select>
                <div className="results-list-search-select-arrow material-symbols-rounded">expand_more</div>
              </div>
              <button className="results-mobile-filter-trigger" onClick={toggleFiltersOverlay}>
                <span className="material-symbols-rounded">tune</span>
                {filterCount > 0 ? <span className="results-mobile-filter-count">{filterCount}</span> : ''}
              </button>
            </div>
          </div>
          <div className="results-list-overflow">
            {!allHotels.length == 0 ? (
              <SimpleBar
                className={filteredHotels.length > 0 ? 'results-list-simplebar' : 'results-list-simplebar empty'}
              >
                {allHotels && allHotels?.length > 0 && (
                  <div className="results-list-overflow-inner">
                    <p className="results-list-count">
                      {Showing} {filteredHotels.length} {Of} {allHotels.length} {filterCount > 0 ? Filtered : ''}{' '}
                      {allHotels?.length === 1 ? Result : Results}
                    </p>
                    {filteredHotels.map((hotel, index) => (
                      <ResultsListItem
                        key={index}
                        index={hotel.VendorID}
                        name={hotel.Name.toString()}
                        address={hotel?.Address_1?.toString()}
                        address2={hotel?.Address_2?.toString()}
                        city={hotel.City.toString()}
                        state={hotel.State.toString()}
                        zip={hotel.Zip.toString()}
                        phone={hotel.Phone.toString()}
                        ada={hotel.Amenities.ADA.toString()}
                        ada_comments={hotel.Amenities.ADA_Comments.toString()}
                        kitchen={hotel.Amenities.Kitchen.toString()}
                        kitchen_comments={hotel.Amenities.Kitchen_Comments.toString()}
                        pet={hotel.Amenities.Pets.toString()}
                        pets_comments={hotel.Amenities.Pets_Comments.toString()}
                        images={hotel.Images}
                        distance={hotel.Distance}
                        openOverlay={() => handleHotelSelection(hotel, hotel.VendorID, index, 'list')}
                        onMouseEnter={() => setHoveredHotel(hotel)} // Set the hovered hotel on mouse enter
                        onMouseLeave={() => setHoveredHotel(null)} // Clear the hovered hotel on mouse leave
                        trackCallClick={() =>
                          trackCallClick(
                            localTranslationContext.language,
                            hotel.VendorID,
                            hotel.Name,
                            !!hotel.Images?.ImageURL?.length,
                            index,
                            hotel.Distance,
                            'list'
                          )
                        }
                      />
                    ))}
                  </div>
                )}
                {/* {filteredHotels && filteredHotels.length === 0 && (
                  <div className="results-list-overflow-inner">
                    <div className="result-error-message">{resultErrorMessage}</div>
                  </div>
                )} */}
                {filteredHotels.length > 0 &&
                filteredHotels.length <= 2500 &&
                nextRadius != undefined &&
                filteredHotels.length !== 0 &&
                filteredHotels.length < 10 &&
                !(!urlParams.isGeo && nextRadius === 10000) ? (
                  <div className="results-expand-wrap">
                    <button className="results-expand-button" onClick={() => handleExpandingRadius()}>
                      {ExpandSearchRadius.replace(
                        '{radius}',
                        (urlParams.isGeo && nextRadius === 10000) || (!urlParams.isGeo && nextRadius === 6000)
                          ? Unlimited.toLowerCase()
                          : nextRadius
                      ).replace('{miles}', Miles)}
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </SimpleBar>
            ) : (
              ''
            )}
            {!isLoading && filteredHotels.length === 0 ? (
              <NoResults
                isGeo={urlParams.isGeo}
                setIsLoading={setIsLoading}
                isFilter={urlParams.filtersInUse}
                nextRadius={nextRadius}
                resultHotelCount={allHotels.length}
                displayedHotelCount={filteredHotels.length}
              />
            ) : (
              ''
            )}
          </div>
          <div className="results-list-mobile-bottom-float">
            {filteredHotels && filteredHotels.length > 0 ? (
              <button className="results-list-toggle-view" onClick={handleMobileMapToggle}>
                {isMobileMapOpen ? ListView : MapView}
              </button>
            ) : (
              ''
            )}

            {urlParams.filtersInUse ? (
              <button className="results-list-filter-clear" onClick={handleClearFilter}>
                {ClearFilters}
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
        {selectedHotel && showResultsOverlay && 
        <ResultsOverlay
          selectedHotel={selectedHotel}
          onClose={() =>setShowResultsOverlay(false)}
          show={showResultsOverlay}
          index={selectedHotelIndex}
          lastFocusedElement={lastFocusedElement}
          trackCallClick={() =>
            trackCallClick(
              localTranslationContext.language,
              selectedHotel.VendorID,
              selectedHotel.Name,
              !!selectedHotel.Images?.ImageURL?.length,
              'n/a',
              selectedHotel.Distance,
              sessionStorage.getItem('viewSource')
            )
          }
        />}
        {!isLoading && filteredHotels && filteredHotels.length > 0 && (
          <MapContainer
            data={filteredHotels}
            radius={urlParams.radius}
            hoveredHotel={hoveredHotel}
            handleHotelSelection={handleHotelSelection}
          />
        )}
        {refreshOverlayOpen && <RefreshOverlay setRefreshOverlayOpen={setRefreshOverlayOpen} />}
        {error.show && <DynamicErrorOverlay error={error} setIsLoading={setIsLoading} />}
      </div>
    </ViewedHotelsProvider>
  );
}

export default Results;
