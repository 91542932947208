import { useState } from 'react';
import apiAdress from '../utils/apiAddress';

//Currently used by NoResults

export const fetchHotelExpandingRadius = async (latitude, longitude, radius, regNum) => {
  const [error, setError] = useState({
    type: '',
    isGeo: '',
    show: false,
  });

  try {
  console.log('fetchHotelExpandingRadius **************************************************');
  const res = await fetch(
      `${apiAdress}/evac_hotel_api.php?validate=FEMAEvacAPI&latitude=${latitude}&longitude=${longitude}&radius=${radius}&reg_number=${regNum}`,
      {
        method: 'GET',
        headers: { 'Content-Type': '*' },
      }
    );
    const data = await res.json();
    if (data.Status.toLowerCase() === 'success') {
      data.Hotels.forEach((hotel) => {
        let distance = getDistanceFromLatLonInKm(latitude, longitude, hotel.Latitude, hotel.Longitude);
        hotel.Distance = Math.round(distance * 10) / 10;
      });
      let newHotels = data.Hotels.sort((a, b) => (a.Distance > b.Distance ? 1 : -1));
      return newHotels;
    }
  } catch (err) {
    setError({
      type: 'unexpected',
      isGeo: '',
      show: true,
    });
    throw err;
  }
};
