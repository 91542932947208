import React from 'react';

const ErrorContext = React.createContext();

export function ErrorProvider({ children }) {
  const [error, setError] = React.useState({
    type: '',
    isGeo: '',
    show: false,
  });

  const value = {
    error,
    setError,
  };

  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
}

export function useError() {
  const context = React.useContext(ErrorContext);
  if (!context) {
    throw new Error('useError must be used within a ErrorProvider');
  }
  return context;
}
