import group from '../../assets/group.png';
class DefaultRenderer {
  constructor() {
      this.activeCluster = null; // keep track of active cluster
  }
  
  render({ count, position }, stats, map) {
      const defaultClusterIcon = {
          url: group,
          scaledSize: new window.google.maps.Size(50, 50),
      };

      return new window.google.maps.Marker({
          position,
          icon: defaultClusterIcon,
          label: {
              text: String(count),
              color: "rgba(0, 82, 136, 1)",
              fontSize: "12px",
          },
          zIndex: 1000 + count,
      });
  }
}

export default DefaultRenderer;
