import React, { useContext, useRef, useEffect } from 'react';
import './ErrorOverlay.css';
import { translationContext } from '../../App';
import { useError } from '../../contexts/ErrorContext';
import { urlParamsContext } from '../../App';
import { fetchHotelExpandingRadius } from '../../services/fetchHotelExpandingRadius';
const BulletPointsForInvalidReg = ({ RegistrationInvalidTsaSubText }) => {
  return <div id={'subtext2'} dangerouslySetInnerHTML={{ __html: RegistrationInvalidTsaSubText }}></div>;
};
const BulletPointsForNoHotels = ({ SubtextNoParticipatingHotels }) => {
  return <div id={'subtext1'} dangerouslySetInnerHTML={{ __html: SubtextNoParticipatingHotels }}></div>;
};

export const DynamicErrorOverlay = ({ error, setIsLoading }) => {
  const { type, isGeo, show } = error;
  const overlayRootRef = useRef(null);
  const closeButtonRef = useRef(null);
  const localTranslationContext = React.useContext(translationContext);
  const dictionary = localTranslationContext.translations;
  const { errorOverlayOpen, setErrorOverlayOpen } = useContext(translationContext);
  const { setError: setStateError } = useError();
  const urlParams = React.useContext(urlParamsContext);
  const RegistrationInvalidTsa = dictionary.find((translation) => translation.code === 'Registration-Invalid-Tsa').text;
  const RegistrationInvalidTsaSubText = dictionary.find(
    (translation) => translation.code === 'Registration-Invalid-Tsa-Subtext'
  ).text;

  const NoParticipatingHotels = dictionary.find((translation) => translation.code === 'No-Participating-Hotels').text;
  const ExceptionError = dictionary.find((translation) => translation.code === 'Exception-Error').text;
  const NoResultsReturned = dictionary.find((translation) => translation.code === 'No-Results-Returned').text;
  const ExpandSearchRadius = dictionary.find((translation) => translation.code === 'Expand-Search-Radius').text;
  const SubtextNoParticipatingHotels = dictionary.find(
    (translation) => translation.code === 'Subtext-No-Participating-Hotels'
  ).text;

  const PleaseTryAgain = dictionary.find((translation) => translation.code === 'Please-Try-Again').text;

  const errorTypeMap = {
    unexpected: {
      message: ExceptionError,
      showButton: true,
      btnMessage: PleaseTryAgain,
      isexpandRadius: false,
    },
    notValidReg_notTSA: {
      message: RegistrationInvalidTsa,
      subtext: <BulletPointsForInvalidReg RegistrationInvalidTsaSubText={RegistrationInvalidTsaSubText} />,
      isexpandRadius: false,
    },
    noHotelsReturned: {
      message: NoResultsReturned,
      showButton: true,
      btnMessage: ExpandSearchRadius,
      isexpandRadius: true,
    },
    noHotelsMaxRadius: {
      message: NoParticipatingHotels,
      subtext: <BulletPointsForNoHotels SubtextNoParticipatingHotels={SubtextNoParticipatingHotels} />,
      showButton: false,
      isexpandRadius: false,
    },
  };

  const currentErrorType = errorTypeMap[type];

  const handleClose = () => {
    setErrorOverlayOpen(false);
    setIsLoading(false);
    setStateError({ type: '', isGeo: '', show: false });
  };
  const radiusIncrements = [25, 50, 100, 250, 500, 1000, 6000];

  const expandRadius = (initialRadius = urlParams.radius) => {
    let currentIndex = radiusIncrements.indexOf(initialRadius);
    let nextRadius;
    setIsLoading(true);
    handleClose();
    if (isGeo) {
      nextRadius = 10000; // Geofenced case: set radius to 10,000 miles
    } else {
      nextRadius = radiusIncrements[currentIndex + 1];
      if (nextRadius === 6000) {
        // check if the radius exceeds maximum limit
        setIsLoading(false);
        setStateError({ type: 'noHotelsMaxRadius', isGeo: '', show: true });
        return;
      }
    }

    fetchHotelExpandingRadius(urlParams.latitude, urlParams.longitude, nextRadius, urlParams.regNum).then((data) => {
      if (data.length > 0) {
        urlParams.setRadius(nextRadius);
        handleClose();
      } else if (data.length === 0 && currentErrorType.isGeo) {
        setStateError({ type: 'noHotelsMaxRadius', isGeo: '', show: true });
      } else {
        // If there are still no results, call expandRadius again with the next radius
        expandRadius(nextRadius);
      }
    });
  };
  const handleCloseOnEnter = (event) => {
    // Check if 'Enter' was pressed
    if (event.key === 'Enter') {
      handleClose();
    }
  };
  useEffect(() => {
    if (!show) {
      return;
    }

    const overlayRoot = overlayRootRef.current;
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const focusableContent = Array.from(overlayRoot.querySelectorAll(focusableElements));
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    const trapFocus = (event) => {
      if (document.activeElement === firstFocusableElement && event.shiftKey && event.key === 'Tab') {
        event.preventDefault();
        lastFocusableElement.focus();
      } else if (document.activeElement === lastFocusableElement && !event.shiftKey && event.key === 'Tab') {
        event.preventDefault();
        firstFocusableElement.focus();
      }
    };

    overlayRoot.addEventListener('keydown', trapFocus);
    // focus on closebutton initially
    closeButtonRef.current.focus();

    return () => overlayRoot.removeEventListener('keydown', trapFocus);
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div ref={overlayRootRef} className="error-overlay-wrap">
      <div className="error-overlay">
        <a className="error-overlay-close" onClick={handleClose} ref={closeButtonRef} tabIndex="0" onKeyDown={handleCloseOnEnter}>
          <span className="material-symbols-rounded">close</span>
        </a>
        <div className="error-overlay-centered-icon material-symbols-rounded">warning</div>
        <p className="error-overlay-headline" dangerouslySetInnerHTML={{ __html: currentErrorType.message }}></p>
        {currentErrorType.subtext && <div className="error-overlay-content">{currentErrorType.subtext}</div>}
        {currentErrorType.showButton && (
          <div className="error-overlay-button-wrap">
            <a className="error-overlay-button" onClick={currentErrorType.isexpandRadius ? expandRadius : handleClose}>
              {currentErrorType.btnMessage}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
