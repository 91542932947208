import React from 'react';
import { translationContext } from '../../App';

function DetailsInfo({ pets, pets_comments, ada, ada_comments, kitchen, kitchen_comments }) {
  const translations = React.useContext(translationContext);
  const dictionary = translations.translations;
  const PetsAllowed = dictionary.find((translation) => translation.code === 'Pets-Allowed').text;
  const PetsNotAllowed = dictionary.find((translation) => translation.code === 'Pets-Not-Allowed').text;
  const ADAAccommodations = dictionary.find((translation) => translation.code === 'ADA-Accommodations').text;
  const NoADAAccommodations = dictionary.find((translation) => translation.code === 'No-ADA-Accommodations').text;
  const MealsProvided = dictionary.find((translation) => translation.code === 'Meals-Provided').text;
  const NoMealsProvided = dictionary.find((translation) => translation.code === 'No-Meals-Provided').text;
  return (
    <span className="details-info">
      <span className="details-info-item">
        <span
          className={`details-info-icon material-symbols-rounded ${pets === 'Y' ? 'green' : ''}`}
          aria-hidden="true"
        >
          {pets === 'Y' ? 'check_circle' : 'block'}
        </span>
        <span className="details-info-name">
          {pets === 'Y' ? PetsAllowed : PetsNotAllowed}{' '}
          {pets_comments !== '' && pets_comments.toLowerCase() !== 'null' && <br />}
          {pets_comments.toLowerCase() !== 'null' && pets_comments}
        </span>
      </span>
      <span className="details-info-item">
        <span className={`details-info-icon material-symbols-rounded ${ada === 'Y' ? 'green' : ''}`} aria-hidden="true">
          {ada === 'Y' ? 'check_circle' : 'block'}
        </span>
        {ada === 'Y' ? (
          <span className="details-info-name">
            {ADAAccommodations} {ada_comments !== '' && ada_comments.toLowerCase() !== 'null' && <br />}
            {ada_comments.toLowerCase() !== 'null' && ada_comments}
          </span>
        ) : (
          <span className="details-info-name">{NoADAAccommodations}</span>
        )}
      </span>
      <span className="details-info-item">
        <span
          className={`details-info-icon material-symbols-rounded ${kitchen === 'Y' ? 'green' : ''}`}
          aria-hidden="true"
        >
          {kitchen === 'Y' ? 'check_circle' : 'block'}
        </span>
        <span className="details-info-name">{kitchen === 'Y' ? MealsProvided : NoMealsProvided}</span>
        <span className="details-info-name">
          {kitchen_comments !== '' && kitchen_comments.toLowerCase() !== 'null' && <br />}
          {kitchen_comments.toLowerCase() !== 'null' && kitchen_comments}
        </span>
      </span>
    </span>
  );
}

export default DetailsInfo;
