import React, { useEffect, useState } from 'react';

const ViewedHotelsContext = React.createContext();

const ViewedHotelsProvider = ({ children }) => {
  const [viewedHotels, setViewedHotels] = useState(
    JSON.parse(sessionStorage.getItem('viewedHotels')) || []
  );
  const [calledHotels, setCalledHotels] = useState(
    JSON.parse(sessionStorage.getItem('calledHotels')) || []
  );

  useEffect(() => {
    sessionStorage.setItem('viewedHotels', JSON.stringify(viewedHotels));
    sessionStorage.setItem('calledHotels', JSON.stringify(calledHotels));
  }, [viewedHotels, calledHotels]);

  const addViewedHotel = (index) => {
    setViewedHotels(prevHotels => [...prevHotels, index]);
  };

  const addCalledHotel = (index) => {
    setCalledHotels(prevHotels => [...prevHotels, index]);
  };

  return (
    <ViewedHotelsContext.Provider value={{ viewedHotels, calledHotels, addViewedHotel, addCalledHotel }}>
      {children}
    </ViewedHotelsContext.Provider>
  );
};

export { ViewedHotelsProvider, ViewedHotelsContext };
